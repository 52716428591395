<!--
 * @Author: zhai
 * @Description: 编辑
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="100px">
            <div class="Industry-box">
                <div class="auxiliary-line">
                    <p>1、您是否有行业内的专业资格证书？</p>
                    <el-form-item class="same-line" label-width="0" prop="professional_qualification" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.professional_qualification">
                            <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p>2、您是否在期刊或杂志上发表过论文、文章、书籍等（与工作、资质有关的）？</p>
                    <el-form-item class="same-line" label-width="0" prop="paper" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.paper">
                            <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p>3、您是否有大学奖学金证明、专利证书、获奖证书（工作单位颁发的可以）？</p>
                    <el-form-item class="same-line" label-width="0" prop="scholarship" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.scholarship">
                            <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p>4、您是否被媒体采访过，专访您的文章被发表在杂志上或网络上？</p>
                    <el-form-item class="same-line" label-width="0" prop="media_interview" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.media_interview">
                            <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p class="no-required">5、请填写您的学业成绩、曾取得的奖项等</p>
                    <el-form-item class="same-line" label-width="0">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.academic_awards"
                            maxlength="1000"
                            show-word-limit
                        />
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p class="no-required">6、请填写您在所属的专业界别内曾获取由国家或国际认可/著名的团体颁授的专业资格、曾取得的奖项等</p>
                    <el-form-item class="same-line" label-width="0">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.professional_awards"
                            maxlength="1000"
                            show-word-limit
                        />
                    </el-form-item>
                </div>

                <div class="auxiliary-line">
                    <p class="no-required">7、请填写其他能对申请带来帮助的重要资料（如：拥有有利于申请的社会任职、相关国际人脉/业务或随行人员所具备的优势点等）</p>
                    <el-form-item class="same-line" label-width="0">
                        <el-input
                            class="mt-10"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入"
                            v-model="userForm.other_information"
                            maxlength="1000"
                            show-word-limit
                        />
                    </el-form-item>
                </div>

            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Auxiliary",
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            auxiliaryArr: [
                {
                    txt: "是",
                    val: 1,
                },
                {
                    txt: "否",
                    val: 2,
                },
            ],
        };
    },
    created() {
        this.userForm = this.data.question;
        console.log(this.userForm)
    },
    methods: {
        // 校验
        save () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate( valid => {
                    if (valid) {
                        resolve(valid)
                    } else {
                        reject();
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.auxiliary-line > p {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    color: #606266;
    &.no-required {
        &::before{
            content: "";
        }
    }
    &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 2px;
        color: red;
    }
}
.industry-box {
    padding: 0 16px;
    position: relative;

    /deep/.el-radio__label {
        display: none;
    }
}
</style>
