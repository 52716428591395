<!--
 * @Author: zhai
 * @Description: 配偶
-->
<template>
    <div>
        <div class="box-title mt-20">
            <span>配偶</span>
            <div class="title-btn">
                <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                <el-button v-if="isEdit" @click="cancel">取消</el-button>
                <el-button v-if="isEdit" type="primary" :loading="isLoading" @click="saveSuccess">保存</el-button>
            </div>
        </div>
        <!-- 回显信息 -->
        <Detail v-if="!isEdit" :data="data" class="pl-15 pr-15" />
        <!-- 编辑 -->
        <Edit ref="editForm" v-loading="isLoading" v-if="isEdit" :data="data"/>
    </div>
</template>

<script>
import Detail from './C/Detail.vue'
import Edit from './C/Edit.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        Detail,
        Edit
    },
    data() {
        return {
            isEdit: false,
            oldData: {},
            isLoading: false,
        }
    },
    methods: {
        editInfo() {
            this.isEdit = true;
            this.oldData = JSON.parse(JSON.stringify(this.data.spouse))
        },
        // 保存成功
        saveSuccess() {
            const edit = this.$refs['editForm'].save();
            Promise.all([edit]).then(() => {
                //全部验证通过就会走这里
                this.postData()
            }, err => {
                this.$utils.scrollIntoError(this);
            })
        },
        async postData() {
            this.isLoading = true;
            try {
                const { code } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message.success("保存成功！");
                    this.isEdit = false;
                }
            } finally {
                this.isLoading = false
            }
        },
        cancel() {
            this.data.spouse = JSON.parse(JSON.stringify(this.oldData));
            this.isEdit = false;
        }
    }
}
</script>

<style lang="less" scoped>
.box-title {
    padding: 8px 15px;
    background: #f2f2f2;
    color: #000;
    display: flex;
    align-items: center;
    span {
        flex: 1;
        font-weight: bold;
    }
    .title-btn {
        float: left;
    }
}
</style>