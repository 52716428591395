<template>
    <div class="pdf-other-bg2">
        <div class="other-user-hk absolute">
            <img :class="['correct']" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 保证人姓名 -->
        <div class="other-user-name absolute" v-pdfbg:username="{ value: user.username, id: 'pdf-user' }">
            {{ user.username }}
        </div>
        <!-- 保证人姓名拼音 -->
        <div class="other-user-pinyin absolute" v-pdfbg:username_pinyin="{ value: user.username_pinyin.family_name, id: 'pdf-user' }">
            <div class="given_name_div" v-for="(givenName,index) in user.username_pinyin.family_name" :key="index">
                {{givenName}}
            </div>
        </div>
        <div class="other-user-pinyin2 absolute" v-pdfbg:username_pinyin="{ value: user.username_pinyin.given_name, id: 'pdf-user' }">
            <div class="given_name_div" v-for="(givenName2,index) in user.username_pinyin.given_name" :key="index">
                {{givenName2}}
            </div>
        </div>
        <!-- 曾用名 -->
        <div class="other-used_name2 absolute" v-pdfbg:used_name="{ value: user.used_name, id: 'pdf-user' }">
            {{ user.used_name }}
        </div>
        <!-- 性别 勾选-->
        <div class="other-user-sex absolute" v-pdfbg:user_sex="{ value: user.sex, id: 'pdf-user' }">
            <img :class="['correct',user.sex === 1?'boy':'girl']" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 生日 -->
        <div class="other-user-birthday" v-pdfbg:user_birthday="{ value: user.birthday, id: 'pdf-user' }">
            <ul>
                <li class="birthday-year">{{user.birthday.split('-')[0]}}</li>
                <li class="birthday-month">{{user.birthday.split('-')[1]}}</li>
                <li class="birthday-day">{{user.birthday.split('-')[2]}}</li>
            </ul>
        </div>
        <!-- 香港身份证-->
        <div v-if="user.has_hk_id === 1" class="other-user-hk_id_number absolute" v-pdfbg:user_hk_id_number="{ id: 'pdf-user' }">
            <span v-for="(item,index) in user.hk_id_number" :key="index" :class="`hk_id_number${index}`">{{item}}</span>
        </div>
        <!-- 现居住地址  -->
        <div class="certificates_address absolute">
            香港湾仔告士打道80号 17/F <br>
            银河国际移民顾问有限公司
        </div>
        <!-- 出生地点 -->
        <div class="birth_place_aboard absolute" v-pdfbg:birth_place_aboard="{ value: user.birth_place_aboard === 0 ? user.birth_place.area.join('') : user.birth_place.foreign, id: 'pdf-user' }">
            {{user.birth_place_aboard === 0 ? user.birth_place.area.join('').replace('市辖区','') : user.birth_place.foreign}}
        </div>
        <!-- 国籍 -->
        <div class="user_nationality absolute" v-pdfbg:user_nationality="{ value: user.nationality?user.nationality.split('-')[0]:'', id: 'pdf-user' }">
            {{ user.nationality?user.nationality.split('-')[0]:'' }}
        </div>
        <!-- 证件类别 大陆为港澳通行证 国外和香港澳门为护照 -->
        <div v-if="user.nationality == '中国-China'">
            <div class="other-user-nationality absolute" v-pdfbg:hk_macao_pass_name="{ id: 'pdf-user' }">
                港澳通行证
            </div>
            <div class="other-user-nationality-number absolute" v-pdfbg:hk_macao_pass_number="{ value:user.certificates['hk_macao_pass'].number, id: 'pdf-user' }">
                {{user.certificates['hk_macao_pass'].number}}
            </div>
        </div>
        <div v-else>
            <div class="other-user-nationality absolute" v-pdfbg:passport_name="{ id: 'pdf-user' }">
                护照
            </div>
            <div class="other-user-nationality-number absolute" v-pdfbg:passport_number="{ value:user.certificates['passport'].number, id: 'pdf-user' }">
                {{user.certificates['passport'].number}}
            </div>
        </div>
        <!-- 联络号码  -->
        <div class="user_mobile">
            69534057
        </div>
        <!-- 电邮地址 -->
        <div class="info_em">
            <div class="info_em_div" v-for="(item,index) in info_em">{{item}}</div>
        </div>
        <!-- 关系  -->
        <div class="other-user-relationship absolute">
            夫妻
        </div>
        <!-- 保证人在港身份 -->
        <div class="other-user-other absolute">
            <img :class="['correct']" src="@/assets/img/correct.png" alt="">
        </div>
        <div class="other-user-other-text absolute">
            申请香港优才计划
        </div>
        <!-- 婚姻状况 勾选 -->
        <div class="user_married" v-pdfbg:user_married="{  id: 'pdf-user' }">
            <img :class="['correct',`married${user.married}`]" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 职业 -->
        <div class="other-user-works" v-pdfbg:works-top-none="{ id: 'works-top' }">
            <span v-for="(item,index) in works.works" :key="index">
                {{works.works[index].position}} <span v-if="index+1 < works.works.length">,</span>
            </span>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    components:{
    },
    data() {
        return {
            info_em:'dochk@galaxyoversea.com',
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['user']),
        ...mapState('previewPdfGifted', ['works'])
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.correct{
    width: 10px;
}
.red {
    color: red;
}
.absolute{
    position: absolute;
}
.given_name_div{
    display: inline-block;
    width: 20.3px;
    text-align: center;
}
.other-user-hk{
    top: 99px;
    left: 42px;
}
.other-user-name{
    top: 187px;
    left: 27px;
    width: 771px;
    height: 28px;
    padding: 5px 0 0 158px;
}
.other-used_name2{
    top: 281px;
    left: 404px;
    width: 394px;
    height: 41px;
    padding: 12px 0 0 83px;
}
.other-user-pinyin{
    top: 214px;
    left: 27px;
    width: 771px;
    height: 33px;
    padding: 7px 0 0 108px;
}
.other-user-pinyin2{
    top: 247px;
    left: 27px;
    width: 771px;
    height: 33px;
    padding: 7px 0 0 108px;
}
.other-user-sex{
    top: 322px;
    left: 27px;
    width: 376px;
    height: 37px;
    .boy{
        position:absolute;
        top: 14px;
        left: 56px;
    }
    .girl{
        position: absolute;
        top: 15px;
        left: 146px;
    }
}
.other-user-birthday{
    width: 376px;
    height: 50px;
    position: absolute;
    top: 361px;
    left: 27px;
    .birthday-year{
        position: absolute;
        top: 13px;
        left: 199px;
        letter-spacing: 13px;
    }
    .birthday-month{
        position: absolute;
        top: 13px;
        left: 145px;
        letter-spacing: 13px;
    }
    .birthday-day{
        position: absolute;
        top: 13px;
        left: 89px;
        letter-spacing: 13px;
    }
}
.other-user-hk_id_number{
    width: 376px;
    height: 50px;
    position: absolute;
    top: 412px;
    left: 27px;
    padding: 15px 0 0 165px;
    span{
        letter-spacing: 13px;
    }
    .hk_id_number0{
        margin-right: 20px;
    }
    .hk_id_number6{
        margin-right:8px;
    }
}
.certificates_address{
    left: 422px;
    top: 340px;
    width: 288px;
    height: 46px;
    line-height: 40px;
}
.birth_place_aboard{
    left: 27px;
    top: 462px;
    width: 376px;
    height: 28px;
    padding: 8px 0 0 76px;
}
.user_nationality{
    left: 27px;
    top: 491px;
    width: 376px;
    height: 28px;
    padding: 6px 0 0 76px;
}
.other-user-nationality{
    left: 27px;
    top: 519px;
    width: 376px;
    height: 28px;
    padding: 6px 0 0 114px;
}
.other-user-nationality-number{
    left: 27px;
    top: 549px;
    width: 376px;
    height: 28px;
    padding: 6px 0 0 114px;
}
.user_mobile{
    position: absolute;
    left: 167px;
    top: 587px;
    letter-spacing: 12.5px;
}
.info_em{
    position: absolute;
    width: 767px;
    height: 31px;
    top: 646px;
    left: 25px;
    box-sizing: border-box;
    padding-left: 136px;
    padding-top: 7px;
    .info_em_div{
        width: 17.6px;
        display: inline-block;
        text-align: center;
    }
}
.other-user-relationship{
    left: 160px;
    top: 693px;
}
.other-user-other{
    left: 638px;
    top: 749px;
}
.other-user-other-text{
    left: 668px;
    top: 772px;
}
.user_married{
    position: absolute;
    top: 909px;
    left: 26px;
    width: 771px;
    height: 34px;
    .correct{
        position: absolute;
        margin-top: 11px;
    }
    .married2{
        left: 106px;
    }
    .married1{
        left: 232px;
    }
    .married4{
        left: 475px;
    }
    .married3{
        left: 350px;
    }
    .married5{
        left: 599px;
    }
}
.other-user-works{
    position: absolute;
    top: 944px;
    left: 26px;
    width: 424px;
    height: 37px;
    padding: 10px 0 0 69px;
}
</style>