<!--
 * @Author: filename
 * @Description: 学历及资格证书
-->

<template>
    <Container title="主申请人大学及以上的【所有】学历及资格证书" padding="0" id="pdf-education">
        <div id="pdf-education-top"></div>
        <div slot="handle">
            <el-button @click="addQualifications">新增证书</el-button>
            <el-button @click="addEducation">新增学历</el-button>
        </div>
        <!-- <div class="description-box" id="pdf-education-score">
            <p>{{data.component_score.college.answer}}，得分<i style="color: red;">{{data.component_score.college.score}}</i>分；</p>
            <p class="mt-10">{{data.component_score.degree.answer}}，得分<i style="color: red;">{{data.component_score.degree.score}}</i>分；</p>
        </div> -->
        <div v-if="info">
            <!-- 学历 -->
            <Education ref="education" :data.sync="data" />
            <!-- 专业资格证书 -->
            <Qualifications ref="qualifications" :data.sync="data" />
            <!-- 学历证书姓名及出生日期正确性 -->
            <EducationQuestions :data="data" />
        </div>
    </Container>
</template>

<script>
import Education from './C/Education/Education.vue'
import Qualifications from './C/Qualifications/Qualifications.vue'
import EducationQuestions from './C/EducationQuestions/EducationQuestions.vue'
export default {
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Education,
        Qualifications,
        EducationQuestions
    },
    created() {
        this.data = JSON.parse(JSON.stringify(this.info))
    },
    data() {
        return {
            data: {},
            educationParm: {
                isEdit: true,
                isLoading: false,
                college: '',
                type: '',
                admission_time: '',
                graduate_time: '',
                professional: '',
                background: '',
                degree_type: '',
                school_address: {
                    details: '',
                    country: '',
                    area: [],
                    foreign: ''
                },
                match_listing: 0
            },
            qualificationsParm: {
                isEdit: true,
                isLoading: false,
                qualification: '',
                college: '',
                promulgation: '',
                college_address: {
                    details: '',
                    country: '',
                    area: [],
                    foreign: ''
                },
                match_listing: 0
            }
        }
    },
    methods: {
        // 新增学历
        addEducation() {
            const isEditNow = this.data.education.filter(item => item.isEdit === true)
            if(isEditNow.length > 0) {
                this.$message.error("请先保存正在编辑或者新增的学历信息！");
                return
            }
            const educationParm = JSON.parse(JSON.stringify(this.educationParm))
            this.data.education.push(educationParm)
            this.$refs.education.anchorPoint()
            this.$refs.education.pdfEducation()
        },
        // 新增证书
        addQualifications() {
            const isEditNow = this.data.education.filter(item => item.isEdit === true)
            if(isEditNow.length > 0) {
                this.$message.error("请先保存正在编辑或者新增的证书信息！");
                return
            }
            const qualificationsParm = JSON.parse(JSON.stringify(this.qualificationsParm))
            this.data.qualification.push(qualificationsParm)
            this.$refs.qualifications.anchorPoint()
        }
    }
}
</script>

<style lang="less" scoped>

</style>
