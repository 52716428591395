<!--
 * @Author: zhai
 * @Description: 主申人
-->
<template>
    <div>
        <div v-for="(item, index) in data.child" :key="index" class="mt-20">
            <div class="box-title">
                <span>子女 - {{ item.subset_name }}</span>
                <div class="title-btn">
                    <el-button v-if="!item.isEdit" type="primary" @click="item.isEdit = true">编辑</el-button>
                    <el-button v-if="item.isEdit" @click="item.isEdit = false">取消</el-button>
                    <el-button v-if="item.isEdit" type="primary" :loading="isLoading" @click="saveSuccess(index)">保存</el-button>
                </div>
            </div>
            <!-- 回显信息 -->
            <Detail v-if="!item.isEdit" :data="item" class="pl-15" />
            <!-- 编辑 -->
            <Edit v-else :ref="'editForm' + index" :data="item" v-loading="isLoading" :index="index" @saveSeccess="editOk(index)" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Detail from './C/Detail.vue'
import Edit from './C/Edit.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Detail,
        Edit
    },
    data() {
        return {
            isEdit: false,
            oldData: [],
            fromData: {},
            isLoading: false,
        }
    },
    computed: {
        ...mapState('gifted', ["dataList"])
        // data() {
        //     return 
        // }
    },
    // watch:{
    //     'dataList.2.child':{
    //         handler:function(newValue){
    //             console.log(this.dataList[2].child)
    //             // const data = JSON.parse(JSON.stringify(newValue[2].child))
    //             // data.forEach((item, i) => {
    //             //     this.$set(data[i], 'isEdit', false)
    //             // })
    //             // this.data.child = data
    //             // console.log("------:",this.data.child)

    //             // this.disposeData()
    //             // this.$nextTick(() => {
    //             //     this.disposeData()
    //             // })
    //         },
    //         deep:true
    //     }
    // },
    created() {
        this.disposeData();
    },
    mounted() {
    },
    methods: {
        editInfo(index) {
            this.fromData.child[index].isEdit = true;
        },
        // 保存
        saveSuccess(index) {
            this.$refs['editForm'+ index][0].save();
        },
        async editOk(index) {
            try {
                this.isLoading = true
                const parm = JSON.parse(JSON.stringify(this.data))
                parm.child[index] = this.$refs['editForm'+index][0].userForm
                const { code } = await postClientInfo(parm)
                if (code === 200) {
                    this.$message.success("保存成功！");
                    this.data.child[index].isEdit = false
                    this.data.child = parm.child
                    this.disposeData();
                }
            } finally {
                this.isLoading = false
            }
        },
        cancel(index) {
            this.fromData.child[index].isEdit = false;
        },
        disposeData() {
            this.data.child.forEach((item, i) => {
                this.$set(this.data.child[i], 'isEdit', false)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.box-title {
    padding: 8px 15px;
    background: #f2f2f2;
    color: #000;
    display: flex;
    align-items: center;
    span {
        flex: 1;
        font-weight: bold;
    }
    .title-btn {
        float: left;
    }
}
</style>