<!--
 * @Author: Jacob.Zhang
 * @Description: 个人信息表 - 主申请人信息
-->
<template>
    <div v-loading="isLoading">
        <Container title="主申请人" padding="0" id="pdf-user-top">
            <!-- <div class="description-box" id="pdf-user-top-score">
                <p>{{ dataList[0].component_score.age.answer }}，得分<span class="text-red">{{ dataList[0].component_score.age.score }}</span>分</p>
            </div> -->
            <div id="pdf-user">
                <div class="title-box">
                    <span>主申信息</span>
                    <div class="title-btn">
                        <el-button v-if="!isEdit" type="primary" @click="isEdit = true">编辑</el-button>
                        <el-button v-if="isEdit" @click="cancel">取消</el-button>
                        <el-button v-if="isEdit" type="primary" :loading="isLoading" @click="saveInfo">保存</el-button>
                    </div>
                </div>
                <!-- 回显信息 -->
                <UserDetail class="pdf-user-detail" v-if="!isEdit" :info="currentItem" />
                <!-- 编辑 -->
                <User v-if="isEdit" :ref="user.component" :currentItem.sync="user" :loading.sync="isLoading" @update="updateDetail" />
            </div>
        </Container>
    </div>
</template>

<script>
import User from './C/EditUser.vue'
import UserDetail from './C/UserDetail.vue'
import { mapState } from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
        User, UserDetail,
    },
    created() {
        this.currentItem = JSON.parse(JSON.stringify(this.info))
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'user', data: JSON.parse(JSON.stringify(this.info)) })
    },
    computed: {
        ...mapState('gifted', ['dataList']),
        ...mapState('previewPdfGifted', ['user'])
    },
    data() {
        return {
            isLoading: false,
            isEdit: false,
            currentItem: {}, // 主申人信息（编辑&回显共用）
        }
    },
    methods: {
        saveInfo() {
            this.$refs[this.info.component].checkInfo()
        },
        // 更新
        updateDetail() {
            this.currentItem = JSON.parse(JSON.stringify(this.user))
            this.isEdit = false
            // 清空标红
            this.$utils.emptyModuleRed(this, 'pdf-bg1')
        },
        // 取消
        cancel() {
            this.currentItem = JSON.parse(JSON.stringify(this.info))
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'user', data: JSON.parse(JSON.stringify(this.info)) })
            this.isEdit = false
            // 清空标红
            this.$utils.emptyModuleRed(this, 'pdf-bg1')
        }
    }
}
</script>

<style lang="less" scoped>

</style>
