<template>
    <el-form :model="ruleForm" ref="ruleForm" label-position="top" label-width="140px">
        <el-form-item
            label="1、您的所有学历及资格证书上的姓名及出生日期，是否与身份证完全一致 ？  （例如：身份证上是【张四】。学位证上是【张三】，则为不相同）"
            prop="qualification"
            :rules="{ required: true, message: '请选择', trigger: 'change' }"
        >
            <el-radio-group v-model="ruleForm.qualification">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item
            v-if="dataList[0].married != 2"
            label="2、您配偶的大学毕业证和学位证上的姓名及出生日期，是否与身份证完全一致？"
            prop="graduate"
            :rules="{ required: true, message: '请选择', trigger: 'change' }"
        >
            <el-radio-group v-model="ruleForm.graduate">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            ruleForm: {}
        }
    },
    created() {
        this.ruleForm = JSON.parse(JSON.stringify(this.data))
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    methods: {
        // 校验参数
        checkParm() {
            const isCheck = Promise.all([this.$refs['ruleForm'].validate()])
            return isCheck
        }
    }
}
</script>

<style>

</style>