<!--
 * @Author: zhai
 * @Description: 主申人详情
-->
<template>
    <div class="pdt-20">
        <p>1、是否曾被拒绝入境/递解/遣送或要求离开澳门?</p>
        <div>
            <span v-if="data.spouse.child_departure.has === 'Y'">是 - {{data.spouse.child_departure.info}}</span>
            <span v-else-if="data.spouse.child_departure.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p>2、是否曾被拒绝签发签证／进入许可以入境澳门？</p>
        <div>
            <span v-if="data.spouse.child_deny.has === 'Y'">是 - {{data.spouse.child_deny.info}}</span>
            <span v-else-if="data.spouse.child_deny.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {

        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
    p {
        color: #999;
        margin-bottom: 20px;
    }
</style>
