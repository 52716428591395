<!--
 * @Author: filename
 * @Description: 家庭成员-兄弟姐妹信息
-->
<template>
    <div class="mt-20">
        <Container title="兄弟姐妹" padding="0" id="brothers-top">
            <div id="brothers-top-name"></div>
            <div slot="handle">
                <el-button @click="addChild">新增</el-button>
            </div>
            <div v-loading="isLoading" v-if="data.brother.length > 0">
                <div v-for="(item, i) in data.brother" :key="i" :id="'pdf-brothers' + i">
                    <div :class="['title-box', i > 0 ? 'mt-20' : '']">
                        <span>兄弟姐妹{{ i+1 }}</span>
                        <div class="title-btn">
                            <!-- 显示状态下按钮 -->
                            <template v-if="!item.isEdit">
                                <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
                                    <el-button slot="reference">删除</el-button>
                                </el-popconfirm>
                                &nbsp;
                                <el-button type="primary" @click="editInfo(item)">编辑</el-button>
                            </template>
                            <!-- 编辑状态下按钮 -->
                            <template v-else>
                                <el-button :loading="item.isLoading" @click="cancel(item, i)">取消</el-button>
                                <el-button type="primary" :loading="item.isLoading" @click="checkInfo(i)">保存</el-button>
                            </template>
                        </div>
                    </div>
                    <!-- 显示 -->
                    <BrothersAndSistersDetail v-if="!item.isEdit" :data="item" />
                    <!-- 编辑 -->
                    <EditBrothersAndSisters :ref="'brothersAndSisters'+i" v-loading="item.isLoading" v-if="item.isEdit" :data="family.brothers[i]" />
                </div>
            </div>
            <div v-else class="no-data">暂无数据</div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BrothersAndSistersDetail from './C/BrothersAndSistersDetail.vue'
import EditBrothersAndSisters from './C/EditBrothersAndSisters.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        BrothersAndSistersDetail,
        EditBrothersAndSisters
    },
    data() {
        return {
            isLoading: false,
            parm: {
                subset_name_pinyin: '',
                isLoading: false,
                isEdit: true,
                relation: 5,
                subset_name: '',
                used_name: '',
                birthday: '',
                sex: '',
                birth_place_aboard: 0,
                birth_place: {
                    details: '',
                    country: '',
                    area: [],
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                country_option: ''
            }
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['family'])
    },
    created() {
        this.setIsEdit()// pdf预览逻辑
        this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'brothers', data: JSON.parse(JSON.stringify(this.data.brother)) })
    },
    methods: {
        // set isEdit
        setIsEdit() {
            this.data.brother.forEach((item, i) => {
                this.$set(this.data.brother[i], 'isEdit', false)
                this.$set(this.data.brother[i], 'isLoading', false)
            })
        },
        // 新增
        addChild() {
            const isEditBrother = this.data.brother.filter(item => item.isEdit === true)
            if(isEditBrother.length > 0) {
                this.$message.error("请先保存正在编辑或者新增的兄弟姐妹信息！");
                return
            }
            const parm = JSON.parse(JSON.stringify(this.parm))
            this.data.brother.push(parm)
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'brothers', data: JSON.parse(JSON.stringify(this.data.brother)) })
            this.anchorPoint()
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector('#pdf-brothers' + (this.data.brother.length - 1))
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        },
        // 取消
        cancel(item, index) {
            if (item.id) {
                item.isEdit = false
            } else {
                this.data.brother.splice(index, 1)
            }
            this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'brothers', data: JSON.parse(JSON.stringify(this.data.brother)) })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 保存
        async saveInfo(index) {
            try {
                this.data.brother[index].isLoading = true
                const parm = JSON.parse(JSON.stringify(this.data))
                parm.brother[index] = this.$refs['brothersAndSisters'+index][0].ruleForm
                const { code, data } = await postClientInfo(parm)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.brother[index].isEdit = false
                    this.data.brother = data.brother
                    this.setIsEdit()
                }
            } finally {
                this.data.brother[index].isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo(index) {
            Promise.all([this.$refs['brothersAndSisters'+index][0].checkParm()])
            .then(async () => {
                this.saveInfo(index)
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
            this.$utils.emptyModuleRed(this, 'pdf-bg2')
        },
        // 删除
        async del(index) {
            try {
                this.isLoading = true
                this.data.brother.splice(index, 1)
                const { code } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                this.$store.dispatch('previewPdfGifted/setFamilyKeyArr', { key: 'brothers', data: JSON.parse(JSON.stringify(this.data.brother)) })
                }
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>

</style>
