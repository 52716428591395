<template>
    <div class="common-part">
        <el-form :model="ruleForm" ref="ruleForm" label-width="130px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="姓名："
                        prop="subset_name"
                        :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
                    >
                        <el-input v-model="ruleForm.subset_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="曾用名：">
                        <el-input v-model="ruleForm.used_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚前姓氏：">
                        <el-input @input="surnameInput" v-model="ruleForm.surname" placeholder="如有则填写，仅支持输入字母" clearable maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="15">
                            <el-form-item
                                label="姓名拼音："
                                prop="subset_name_pinyin.family_name"
                                :rules="{ required: true, message: '请输入姓氏拼音', trigger: 'blur' }"
                            >
                                <el-input v-model="ruleForm.subset_name_pinyin.family_name" clearable maxlength="50" placeholder="请输入姓氏拼音" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item
                                class="form-item"
                                prop="subset_name_pinyin.given_name"
                                :rules="{ required: true, message: '请输入名称拼音', trigger: 'blur' }"
                            >
                                <el-input v-model="ruleForm.subset_name_pinyin.given_name" clearable maxlength="50" placeholder="请输入名称拼音" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="性别："
                        prop="sex"
                        :rules="{ required: true, message: '请选择性别', trigger: 'change' }"
                    >
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别" clearable style="width: 100%">
                            <!-- <el-option label="未知" :value="0" /> -->
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="出生日期："
                        prop="birthday"
                        :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="ruleForm.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="年 -月 -日"
                            @change="hanldBirthdayClick"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="14">
                            <el-form-item
                                class="radio-box"
                                label="出生城市："
                                prop="birth_place_aboard"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <el-radio-group v-model="ruleForm.birth_place_aboard" @change="handleSelect">
                                    <el-radio :label="0">国内</el-radio>
                                    <el-radio :label="1">国外</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" v-if="ruleForm.birth_place_aboard === 0">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.area"
                                :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                            >
                                <CitySelect v-model="ruleForm.birth_place.area"  style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" v-if="ruleForm.birth_place_aboard === 1">
                            <el-form-item
                                class="form-item"
                                prop="birth_place.foreign"
                                :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                            >
                                <el-input v-model="ruleForm.birth_place.foreign" clearable maxlength="100" placeholder="请输入城市" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item
                                label="职业："
                                prop="occupation"
                                :rules="{ required: true, message: '请选择职业', trigger: 'change' }"
                            >
                                <el-select v-model="ruleForm.occupation" clearable placeholder="请选择" style="width: 100%;" @change="handleOctChange">
                                    <el-option
                                        v-for="(item, i) in workOpts"
                                        :key="i"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                v-if="ruleForm.occupation === '在职'"
                                label="职业名称："
                                prop="occupation_name"
                                :rules="{ required: true, message: '请填写职业名称', trigger: 'blur' }"
                            >
                                <el-input v-model="ruleForm.occupation_name" clearable maxlength="20" placeholder="如在职，请填写目前的岗位名称" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="婚姻状况："
                        prop="married"
                        :rules="{ required: true, message: '请选择婚姻状况', trigger: 'change' }"
                    >
                        <el-select v-model="ruleForm.married" placeholder="请选择" style="width: 100%;">
                            <el-option v-for="(item, index) in $CONFIG.MARRIED" :key="index" :label="item.label" :value="item.value+''" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- 随行 start -->
                <template v-if="ruleForm.follow === 1">
                    <el-col :span="12">
                        <el-form-item
                            label="国籍/地区："
                            prop="nationality"
                            :rules="{ required: true, message: '请输入选择', trigger: 'change' }"
                        >
                            <CountrySelect v-model="ruleForm.nationality" style="width: 100%;" placeholder="请输入对应的内容" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider />
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-width"
                            label="是否与主申请人同住："
                            prop="nows"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.nows">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-width"
                            label="是否永久居留身份："
                            prop="permanent_residence"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.permanent_residence">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="ruleForm.nows === 0">
                        <el-form-item
                            label="现居住地址："
                            prop="address"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <CurrentAddressMacao ref="CurrentAddress" v-model="ruleForm.address" :is_live_oversea_year.sync="ruleForm.is_live_oversea_year"/>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <div style="display: flex;">
                            <el-form-item
                                label="总居留时间："
                                prop="permanent_reside_year"
                                :rules="{ required: true, message: '请输入', trigger: 'blur' }"
                            >
                                <div style="display: flex;">
                                    <el-input
                                        v-model.number="ruleForm.permanent_reside_year"
                                        clearable
                                        maxlength="50"
                                        placeholder="请输入"
                                        oninput="value=value.replace(/[^\d]/g,'')"
                                        style="width: 100px" />
                                    <span class="ml-10">年</span>
                                </div>
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="permanent_reside_month"
                                :rules="{ required: true, message: '请输入', trigger: 'blur' }"
                            >
                                <div style="display: flex;">
                                    <el-input
                                        v-model.number="ruleForm.permanent_reside_month"
                                        clearable
                                        maxlength="50"
                                        placeholder="请输入"
                                        oninput="value=value.replace(/[^\d]/g,'')"
                                        style="width: 100px"
                                    />
                                    <span class="ml-10">月</span>
                                </div>
                            </el-form-item>
                        </div>
                    </el-col> -->
                    <el-col :span="24">
                        <el-divider />
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            class="custom-width"
                            label="申请前十年是否曾居住其他国家/地区："
                            prop="is_area_ten_years"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.is_area_ten_years">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="ruleForm.is_area_ten_years == 1">
                        <el-row v-for="(item, i) in ruleForm.subset_residence" :key="i" :gutter="10">
                            <el-col :span="10">
                                <el-form-item
                                    :prop="'subset_residence.'+i+'.country'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <span slot="label" v-if="i === 0">
                                        <span>居住信息：</span>
                                    </span>
                                    <CountrySelect v-model="item.country" style="width: 100%" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.city'"
                                    :rules="{ required: true, message: '请输入' }"
                                >
                                    <el-input v-model="item.city" clearable auto-complete="off" maxlength="50" placeholder="请输入城市" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.start_time'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.start_time"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="开始时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item
                                    class="form-item"
                                    :prop="'subset_residence.'+i+'.end_time'"
                                    :rules="{ required: true, message: '请选择' }"
                                >
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.end_time"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2" class="add-degree">
                                <span v-if="i===0" @click="addOtherLivingInfo">+</span>
                                <span v-if="i > 0" @click="delOtherLivingInfo(i)" style="padding: 3px 12px;">-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <el-divider />
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-width"
                            label="是否拥有孩子抚养权："
                            prop="custody"
                            :rules="{ required: true, message: '请选择孩子抚养权', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.custody">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            class="custom-width"
                            label="是否为前次婚姻的子女："
                            prop="child_stepchild.has"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.child_stepchild.has">
                                <el-radio label="Y">是</el-radio>
                                <el-radio label="N">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider />
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="目前是否在澳："
                            prop="child_in_hk.has"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="ruleForm.child_in_hk.has">
                                <el-radio label="Y">是</el-radio>
                                <el-radio label="N">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ruleForm.child_in_hk.has === 'Y'">
                        <el-form-item
                            label="获准逗留至："
                            prop="child_in_hk.info"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-date-picker
                                style="width: 100%;"
                                v-model="ruleForm.child_in_hk.info"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="年 -月 -日">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ruleForm.child_in_hk.has === 'Y'">
                        <el-row :gutter="10">
                            <el-col :span="ruleForm.hk_identity === '其他' ? 14 : 24">
                                <el-form-item
                                    label="在澳身份："
                                    prop="hk_identity"
                                    :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                >
                                    <el-select v-model="ruleForm.hk_identity" placeholder="请选择在澳身份" style="width: 100%;" @change="handleHkIdentityChange">
                                        <el-option
                                            v-for="(item, i) in hk_identity_list"
                                            :key="i"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item
                                    v-if="ruleForm.hk_identity === '其他'"
                                    class="form-item"
                                    prop="hk_identity_other"
                                    :rules="{ required: true, message: '请输入在澳身份', trigger: 'blur' }"
                                >
                                    <el-input v-model="ruleForm.hk_identity_other" clearable maxlength="20" placeholder="请输入对应的内容" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </template>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <!-- 随行 end -->
                <el-col :span="12">
                    <el-form-item
                        class="custom-width"
                        label="是否办理过澳门身份证："
                        prop="child_has_hk_id.has"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-radio-group v-model="ruleForm.child_has_hk_id.has" @change="handleChang">
                            <el-radio label="Y">是</el-radio>
                            <el-radio label="N">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        class="custom-width"
                        v-if="ruleForm.child_has_hk_id.has === 'Y'"
                        label="澳门身份证号码："
                        prop="hk_identity_card"
                        :rules="[{ required: true, message: '请输入澳门身份证号码', trigger: 'blur' },
                                { required: true, trigger: 'blur', validator: $rules.validate.macaoId }]">
                        <el-input v-model.trim="ruleForm.hk_identity_card" clearable maxlength="20" placeholder="请输入澳门身份证号码" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template v-if="ruleForm.follow === 1">
            <el-divider />
            <!-- 中国身份证 -->
            <CnidForm ref="CnidForm"
                :card-info="ruleForm.certificates.cn_identity_card"
                :required="false"
            />
            <!-- 港澳通行证 -->
            <el-divider />
            <HkmPass ref="HkmPass" :card-info="ruleForm.certificates.hk_macao_pass" />
            <!-- 护照 -->
            <el-divider />
            <Passport ref="Passport"
                :card-info="ruleForm.certificates.passport"
                :required="!ruleForm.nationality.includes('中国-')"
            />
        </template>
    </div>
</template>

<script>
import CnidForm from '../../../../../../CnidForm/CnidForm.vue'
import HkmPass from '../../../../../../HkmPass/HkmPass.vue';
import Passport from '../../../../../../Passport/Passport.vue';
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        CnidForm,
        HkmPass,
        Passport
    },
    data() {
        return {
            ruleForm: {},
            workOpts: ["在职", "退休", "无职业", "学生"],
            hk_identity_list: ['学生', '就业', '居留', '访问', '其他']
        }
    },
    created() {
        this.ruleForm = this.data
        for(let key in this.ruleForm) {
            if (this.ruleForm[key] == 100) {
                this.ruleForm[key] = ''
            }
            if (key == 'subset_residence') {
                if (this.ruleForm[key].length === 0) {
                    this.ruleForm[key] = [{
                        country: '',
                        city: '',
                        start_time: undefined,
                        end_time: undefined,
                    }]
                }
            }
        }
    },
    watch: {
        'ruleForm.nationality'(newVal) {
            if (this.ruleForm.follow == 1) {
                if(newVal && newVal.includes('中国-')) {
                    this.$refs.Passport.clearCheck()
                }else {
                    // this.$refs.CnidForm.clearCheck()
                }
            }
        },
    },
    methods: {
        // 婚前姓氏格式化
        surnameInput(value){
            this.ruleForm.surname = value.replace(/[^a-zA-Z]/g, "").toLocaleUpperCase()
        },

        // 校验
        checkParm() {
            const ruleFormArray = []
            // 字段校验
            ruleFormArray.push(this.$refs.ruleForm.validate())
            if (this.ruleForm.follow === 1) {
                // 现居住地址校验
                if (this.ruleForm.nows === 0) ruleFormArray.push(this.$refs.CurrentAddress.checkInfo())
                // 中国身份证校验
                ruleFormArray.push(this.$refs.CnidForm.checkInfo())
                // 港澳通行证校验
                ruleFormArray.push(this.$refs.HkmPass.checkInfo())
                // 护照校验
                ruleFormArray.push(this.$refs.Passport.checkInfo())
            }
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 是否办理过澳门身份证
        handleChang() {
            // this.ruleForm.hk_identity_card = ''
        },
        // 出生城市选择
        handleSelect(v) {
            if (v===0) {
                this.ruleForm.birth_place.foreign = ''
            } else {
                this.ruleForm.birth_place.area = []
            }
        },
        // 是否在职
        handleOctChange(v) {
            if (v !== '在职') {
                this.ruleForm.occupation_name = ''
            }
        },
        // 添加学位
        addDegree() {
            this.ruleForm.has_degree_json.push({
                degree: '',
                address: ''
            })
        },
        // 删除学位
        delDegree(i) {
            this.ruleForm.has_degree_json.splice(i, 1)
        },
        // 在澳身份
        handleHkIdentityChange(v) {
            if (v !== '其他') {
                this.ruleForm.hk_identity_other = ''
            }
        },
        // 是否满18周岁
        hanldBirthdayClick(time) {
            //获取出生年月日
            if (!time) {
                return ''
            }
            const yearDate = time.split('-')
            const yearBirth = yearDate[0]
            const monthBirth = yearDate[1]
            const dayBirth = yearDate[2]
            //获取当前年月日并计算年龄
            var myDate = new Date();
            var monthNow = myDate.getMonth() + 1;
            var dayNow = myDate.getDate();
            // console.log(myDate.getFullYear(), yearBirth)
            var age = myDate.getFullYear() - yearBirth;
            if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
                age--;
            }
            // 未满18岁，默认选择未婚
            if (age < 18) {
                this.ruleForm.married = '2'
            }
            // if (!type) {
            //     if (age >= 18) {
            //         this.ruleForm[i].follow = 2
            //         this.ruleForm[i].married = ''
            //     } else {
            //         this.ruleForm[i].married = '2'
            //     }
            // }
            // return age
        },
        // 新增近十年居住信息
        addOtherLivingInfo(){
            this.ruleForm.subset_residence.push({
                country: '',
                city: '',
                start_time: undefined,
                end_time: undefined,
            })
        },
        // 删除近十年居住信息
        delOtherLivingInfo(i) {
            this.ruleForm.subset_residence.splice(i, 1)
        },
    }
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.add-degree {
    span {
        display: inline-block;
        border: 1px solid #aaa;
        padding: 2px 8px;
        border-radius: 20px;
        color: #aaa;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
}
.custom-width {
    /deep/ .el-form-item__label {
        width: auto !important;
    }
    /deep/ .el-form-item__label {
        padding: 0 7px 0 0;
    }
}
.radio-box {
    /deep/ .el-radio {
        margin-right: 10px;
    }
}
</style>
