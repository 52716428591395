<template>
    <div class="common-part">
        <el-form :model="ruleForm" ref="ruleForm" label-width="130px">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="资格证书名称："
                        :rules="{ required: true, message: '请选择资格证书', trigger: 'change' }"
                        prop="qualification"
                    >
                        <!-- <el-input v-model.trim="item.qualification" clearable maxlength="20" placeholder="请输入资格证书名称" /> -->
                        <el-select v-model="ruleForm.qualification" placeholder="请选择资格证书" style="width: 100%;">
                            <el-option
                                v-for="(item,i) in options"
                                :key="i"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="颁授机构全称："
                        :rules="{ required: true, message: '请输入颁授机构全称', trigger: 'blur' }"
                        prop="college"
                    >
                        <el-input v-model="ruleForm.college" clearable maxlength="50" placeholder="颁授机构全名" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="颁授年份："
                        :rules="{ required: true, message: '请选择毕业年月', trigger: 'change' }"
                        prop="promulgation"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="ruleForm.promulgation"
                            type="year"
                            value-format="yyyy"
                            :picker-options="pickerBeginDateBefore"
                            placeholder="颁授年份">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="15">
                            <el-form-item
                                label=" 颁授城市："
                                :rules="{ required: true, message: '请输入颁授城市', trigger: 'blur' }"
                                prop="college_address.country"
                            >
                                <CountrySelect v-model="ruleForm.college_address.country" placeholder="输入匹配国家" @input="handleSelect" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item
                                class="form-item"
                                v-if="ruleForm.college_address.country.indexOf('中国') !== -1 || ruleForm.college_address.country === ''"
                                prop="college_address.area"
                                :rules="{ required: true, message: '请选择城市', trigger: 'change' }"
                            >
                                <CitySelect :country="ruleForm.college_address.country" v-model="ruleForm.college_address.area"  style="width: 100%;" />
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="college_address.foreign"
                                :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                v-else
                            >
                                <el-input v-model="ruleForm.college_address.foreign" clearable placeholder="请输入城市" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="符合清单："
                        prop="match_listing"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-radio-group v-model="data.match_listing">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        }  
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            ruleForm: {},
            options: [
                'CFA(注册金融分析师)',
                'CFP(CFP证书是目前世界上权威的权威理财顾问认证项目之一) ',
                'FRM(金融风险管理师)',
                'FIA(保险精算师)',
                'CPA(注册会计师)',
                'CIIA(注册国际投资分析师)',
                'RFC(财务顾问师)',
                'ACCA(特许公认会计师)',
                'CIA(国际注册内部审计师)',
                'CMA(美国管理会计师)',
                'CQF(国际数量金融工程认证)',
                'CIMA(管理会计师考试)',
                'The Associate Chartered Accountant(英国皇家特许会计师)',
                'FCA(Fellow Chartered Accountant)'
            ]
        }
    },
    created() {
        this.ruleForm = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        // 校验参数
        checkParm() {
            const isCheck = Promise.all([this.$refs['ruleForm'].validate()])
            return isCheck
        },
        // 输入匹配
        handleSelect(v) {
            if (v.indexOf('中国') !== -1) {
                this.ruleForm.college_address_aboard = 0
            } else {
                this.ruleForm.college_address_aboard = 1
            }
            this.ruleForm.college_address.area = []
            this.ruleForm.college_address.foreign = ''
        },
    },
}
</script>

<style lang="less" scoped>
.form {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
</style>
