<!--
 * @Author: zhai
 * @Description: 编辑
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="0">
            <!-- 子女 -->
            <div class="basics-box">
                <div class="basics-title">1、是否曾被拒绝入境/递解/遣送或要求离开澳门?</div>
                <div class="same-line">
                    <el-form-item prop="child_departure.has" :rules="{required: true, message: '请选择', trigger: 'change'}">
                        <el-radio-group v-model="userForm.child_departure.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.child_departure.has === 'Y'" class="input-line" prop="child_departure.info" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                        <el-input v-model.trim="userForm.child_departure.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
            <div class="basics-box">
                <div class="basics-title">2、是否曾被拒绝签发签证／进入许可以入境澳门？</div>
                <div class="same-line">
                    <el-form-item prop="child_deny.has" :rules="{required: true, message: '请选择', trigger: 'change'}">
                        <el-radio-group v-model="userForm.child_deny.has">
                            <el-radio label="N">否</el-radio>
                            <el-radio label="Y">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="userForm.child_deny.has === 'Y'" class="input-line" prop="child_deny.info" :rules="{required: true, message: '请输入', trigger: 'blur'}">
                        <el-input v-model.trim="userForm.child_deny.info" maxlength="200" placeholder="如选择“是“，请详细说明：时间、地点、事件。" clearable />
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script lang="javascript">

export default {
    name: "Children",
    props: {
        data: {
            type: Object,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            userForm: {}
        }
    },
    created() {
        this.userForm = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        // 校验
        save () {
            this.$refs.userForm.validate(async valid => {
                if (valid) {
                    this.$emit('saveSeccess')
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
}

.same-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 47px;

    .el-radio {
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }
    }

    .input-line {
        width: 782px;
    }

    /deep/ .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .el-radio-group {
        width: 140px;
    }
}
.child-mar {
    margin-bottom: 20px;
}
.child-mar:last-child {
    margin-bottom: 0;
}
</style>
