<template>
    <!-- 分数详情 -->
    <div class="pdf-bg5" v-if="dataList.length>0">
        <div v-pdfbg:pdf-user-top-score="{ value: '', id: 'pdf-user-top' }" class="user_score score-left">{{dataList[0].component_score.age.score}}</div>
        <div v-pdfbg:pdf-education-score="{ value: '', id: 'pdf-education' }" class="degree score-left"> {{dataList[3].component_score.degree.score}} </div>
        <div v-pdfbg:pdf-education-score="{ value: '', id: 'pdf-education' }" class="college score-left"> {{dataList[3].component_score.college.score}} </div>
        <div v-pdfbg:works-top-score="{ value: '', id: 'works-top' }" class="history score-left"> {{dataList[4].component_score.history.score}} </div>
        <div v-pdfbg:works-top-score="{ value: '', id: 'works-top' }" class="international score-left"> {{dataList[4].component_score.international.score}} </div>
        <div v-pdfbg:works-top-score="{ value: '', id: 'works-top' }" class="famous_enterprise score-left"> {{dataList[4].component_score.famous_enterprise.score}} </div>
        <div v-pdfbg:talent_listing-score="{ value: '', id: 'talent_listing' }" class="talent_listing score-left"> {{dataList[6].component_score.talent_listing.score}} </div>
        <div v-pdfbg:language-top-score="{ value: '', id: 'language-top' }" class="language score-left"> {{dataList[5].component_score.language.score}} </div>
        <div v-pdfbg:background-top-score="{ value: '', id: 'background-top' }" class="background score-left"> {{dataList[2].background.background_member.has === 'Y'?'5':'0'}} </div>
        <div v-pdfbg:pdf-spouse-score="{ value: '', id: 'pdf-spouse' }" class="spouse score-left"> {{dataList[1].component_score.spouse.score}} </div>
        <div v-pdfbg:children-top-score="{ value: '', id: 'children-top' }" class="children score-left"> {{dataList[1].component_score.children.score}} </div>
        <div class="K score-left"> {{K}} </div>
        <div v-if="dataList[3].component_score.college.score>0" v-pdfbg:pdf-education-score="{ value: '', id: 'pdf-education' }" class="topSchool-name"> {{topSchool.length>0?topSchool.join(','):''}} </div>
        <div v-pdfbg:talent_listing-name="{ value: dataList[6].talent_listing.remark, id: 'talent_listing' }" class="talent_listing-name"> {{dataList[6].talent_listing.remark}} </div>
        <div class="famous-companies-name"> {{ dataList[4].works | formatWorksName }} </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    filters: {
        formatWorksName(arr) {
            return arr.filter(item => item.enterprises_type === 1).map(item => item.company_name).join(',')
        }
    },
    data() {
        return {
            K:0,
            topSchool:[]
        }
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    watch:{
        dataList:{
            handler(val){
                if(val[0]&&val[0].component_score){
                    if(val[3].component_score.college.score  >0){
                        this.topSchool = []
                        val[3].education.map(arr => {
                            if(arr.is_top == 1){
                                this.topSchool.push(arr.college)
                            }
                        })
                    }
                    this.K =
                    Number(val[0].component_score.age.score) +
                    Number(val[3].component_score.degree.score) +
                    Number(val[3].component_score.college.score) +
                    Number(val[4].component_score.history.score) +
                    Number(val[4].component_score.international.score) +
                    Number(val[4].component_score.famous_enterprise.score) +
                    Number(val[6].component_score.talent_listing.score) +
                    Number(val[5].component_score.language.score) +
                    Number(val[1].component_score.spouse.score) +
                    Number(val[1].component_score.children.score) +
                    Number(val[2].background.background_member.has === 'Y'?'5':'0')
                }
            },
            deep:true,
            immediate: true,
        }
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.red {
    color: red;
}
.pdf-bg5 {
    height: 1200px;
}
.score-left{
    left: 590px;
    width: 110px;
    text-align: center;
}
.user_score{
    position: absolute;
    top: 143px;
    height: 27px;
    line-height: 27px;
}
.degree{
    position: absolute;
    top: 260px;
    height: 46px;
    line-height: 46px;
}
.college{
    position: absolute;
    top: 354px;
    height: 27px;
    line-height: 27px;
}
.history{
    position: absolute;
    top: 490px;
    height: 54px;
    line-height: 54px;
}
.international{
    position: absolute;
    top: 544px;
    height: 37px;
    line-height: 37px;
}
.famous_enterprise {
    position: absolute;
    top: 582px;
    height: 80px;
    line-height: 80px;
}
.talent_listing{
    position: absolute;
    top: 691px;
    height: 50px;
    line-height: 50px;
}
.language{
    position: absolute;
    top: 831px;
    height: 28px;
    line-height: 28px;
}
.background{
    position: absolute;
    top: 888px;
    height: 36px;
    line-height: 36px;
}
.spouse{
    position: absolute;
    top: 926px;
    height: 26px;
    line-height: 26px;
}
.children{
    position: absolute;
    top: 953px;
    height: 24px;
    line-height: 24px;
}
.K{
    position: absolute;
    top: 1003px;
    height: 40px;
    line-height: 40px;
}
.topSchool-name{
    position:absolute;
    top: 307px;
    left: 14px;
    width: 567px;
    height: 75px;
    padding: 46px 0 0 157px;
}
.talent_listing-name{
    position:absolute;
    top: 663px;
    left: 31px;
    width: 567px;
    height: 75px;
    padding: 52px 0 0 267px;
}
.famous-companies-name {
    position: absolute;
    top: 642px;
    left: 206px;
    width: 268px;
}
</style>