<!--
 * @Author: zhai
 * @Description: 主申人详情
-->
<template>
    <div class="pdt-20">
        <p id="applicant1">1、是否曾在澳门或其他地方因任何罪行或违法行为被定罪？</p>
        <div>
            <span v-if="data.applicant.has_conviction.has === 'Y'">是 - {{ data.applicant.has_conviction.info }}</span>
            <span v-else-if="data.applicant.has_conviction.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p id="applicant2">2、是否曾在澳门或其他地方被拒入境／递解／遣送或要求离境？</p>
        <div>
            <span v-if="data.applicant.has_departure.has === 'Y'">是 - {{ data.applicant.has_departure.info }}</span>
            <span v-else-if="data.applicant.has_departure.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p id="applicant3">3、是否曾被拒绝签发澳门或其他地方的签证／进入许可？</p>
        <div>
            <span v-if="data.applicant.has_deny.has === 'Y'">是 - {{ data.applicant.has_deny.info }}</span>
            <span v-else-if="data.applicant.has_deny.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p id="applicant4">4、是否曾触犯澳门或任何地方的入境法例？</p>
        <div>
            <span v-if="data.applicant.has_breaking_law.has === 'Y'">是 - {{ data.applicant.has_breaking_law.info }}</span>
            <span v-else-if="data.applicant.has_breaking_law.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p id="applicant5">5、是否曾经使用另一个姓名或身份申请澳门入境签证？</p>
        <div>
            <span v-if="data.applicant.has_other_id.has === 'Y'">是 - {{ data.applicant.has_other_id.info }}</span>
            <span v-else-if="data.applicant.has_other_id.has === 'N'">否</span>
            <span v-else>-</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {

        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
    p {
        color: #999;
        margin-bottom: 20px;
    }
</style>
