<!--
 * @Author: filename
 * @Description: 专业资格证书
-->
<template>
    <div class="mt-20">
        <div class="box-title">
            <span>学历证书姓名及出生日期正确性</span>
            <div class="title-btn">
                <el-button v-if="data.confirm.isEdit" :loading="data.confirm.isLoading" @click="data.confirm.isEdit = false">取消</el-button>
                <el-button v-if="!data.confirm.isEdit" type="primary" @click="editInfo(data.confirm)">编辑</el-button>
                <el-button v-if="data.confirm.isEdit" type="primary" :loading="data.confirm.isLoading" @click="checkInfo">保存</el-button>
            </div>
        </div>
        <!-- 回显 -->
        <EducationQuestionsDetail v-if="!data.confirm.isEdit" :data="data.confirm" />
        <!-- 编辑 -->
        <EditEducationQuestions ref="educationQuestions" v-else  :data="data.confirm" />
    </div>
</template>

<script>
import EditEducationQuestions from './C/EditEducationQuestions.vue'
import EducationQuestionsDetail from './C/EducationQuestionsDetail.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        EducationQuestionsDetail,
        EditEducationQuestions
    },
    data() {
        return {
            ruleForm: {}
        }
    },
    created() {
        this.$set(this.data.confirm, 'isEdit', false)
        this.$set(this.data.confirm, 'isLoading', false)
    },
    methods: {
        // 保存
        async saveInfo() {
            try {
                this.data.confirm.isLoading = true
                const parm = JSON.parse(JSON.stringify(this.data))
                parm.confirm = this.$refs['educationQuestions'].ruleForm
                const { code, data } = await postClientInfo(parm)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.confirm.isEdit = false
                    this.data.confirm = data.confirm
                    this.data.component_score = data.component_score
                    this.$set(this.data.confirm, 'isEdit', false)
                }
            } finally {
                this.data.confirm.isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo() {
            Promise.all([this.$refs['educationQuestions'].checkParm()])
            .then(async () => {
                this.saveInfo()
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.box-title {
    padding: 8px 15px;
    background: #f2f2f2;
    color: #000;
    display: flex;
    align-items: center;
    span {
        flex: 1;
        font-weight: bold;
    }
    .title-btn {
        float: left;
    }
}
</style>
