<!--
 * @Author: filename
 * @Description: 资格证书
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12">
                <span>证书名称：</span>
                <span>{{ data.qualification }}</span>
            </el-col>
            <el-col :span="12">
                <span>颁授机构：</span>
                <span>{{ data.college }}</span>
            </el-col>
            <el-col :span="12">
                <span>颁授年份：</span>
                <span>{{ data.promulgation }}</span>
            </el-col>
            <el-col :span="12">
                <span>颁授城市：</span>
                <span v-if="data.college_address.country.includes('中国')">
                    {{ data.college_address.country + `-` + data.college_address.area[0] + `-` + data.college_address.area[1] }}
                </span>
                <span v-else>
                    {{ data.college_address.country + `-` + data.college_address.foreign }}
                </span>
            </el-col>
            <el-col :span="24">
                <span>符合清单：</span>
                <span>
                    {{ data.match_listing == 0 ? '否' : data.match_listing == 1 ? '是' : ''}}
                </span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
