<!--
 * @Author: zhai
 * @Description: 其它信息
-->
<template>
    <div>
        <Residences :data="info" class="mb-20 anchor anchor5"/>
        <Language :data="info" class="mb-20 anchor anchor6"/>
        <Professional :data="info" class="mb-20 anchor anchor7"/>
        <Speciality :data="info" class="mb-20 anchor anchor8"/>
        <!-- <Talent :data="talentListing" style="margin-bottom: 20px" class="anchor6" /> -->
        <!-- <Asset :data="property" class="mb-20 anchor7"/>
        <Liabilities :data="property" class="mb-20 anchor8"/> -->
    </div>
</template>

<script>
import Language from "./C/Language/Language.vue"
import Professional from "./C/Professional.vue"
import Residences from "./C/Residences.vue"
import Speciality from "./C/Speciality/Speciality.vue"
// import Talent from "./C/Talent.vue"
// import Asset from "./C/Asset.vue"
// import Liabilities from "./C/Liabilities.vue"
import { mapState } from 'vuex'
export default {
    name: "Other",
    components: {
        Language,
        Professional,
        Residences,
        Speciality,
        // Talent,
        // Asset,
        // Liabilities
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
        talentListing: {
            type: Object,
            default: () => {
                return {};
            },
        },
        property: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {

        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>

</style>
