<!--
 * @Author: zhai
 * @Description: 专业技能及行业
-->
<template>
    <div>
         <Container title="专业技能及行业" padding="0" id="pdf-profession">
            <div style="padding: 20px">
                <div id="pdf-profession-content" v-if="!isEdit"><span>{{ option }}</span> - <span style="color: red">{{ ruleForm.remark }}</span></div>
                <el-form v-if="isEdit" :model="ruleForm" ref="ruleForm" inline label-width="100px" class="mt-20">
                    <el-form-item label-width="0" prop="option">
                        <el-select v-model="ruleForm.option" placeholder="请选择" style="width:600px" @change="optionChange">
                            <el-option v-for="(item,index) in optionList" :key="index" :label="item.ind + '-' + item.example" :value="item.value" :rules="[{required: true,message: '请选择',trigger: 'change'}]"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.option === 15" label="" prop="remark" label-width="0" :rules="[{required: true,message: '请输入',trigger: 'blur'}]">
                        <el-input v-model="ruleForm.remark" style="width:260px" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                    <el-button v-if="isEdit" @click="cancel">取消</el-button>
                    <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="saveSuccess">保存</el-button>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { postClientInfo, industryList } from '../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {
            option: '',
            optionList: [],
            ruleForm: {
                option: null,
            },
            isEdit: false,
            isLoading: false,
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['profession'])
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            const { data } = await industryList()
            const industryListData = data.map(item => {
                return {
                    ind:  item.name,
                    example: item.example,
                    value: item.value,
                }
            })
            this.optionList = industryListData
            let labels = industryListData[this.data.profession.option - 1];
            this.option = labels?.ind || '';
            this.ruleForm = JSON.parse(JSON.stringify(this.data.profession));
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'profession', data: JSON.parse(JSON.stringify(this.option)) })
        },
        editInfo() {
            this.isEdit = true;
        },
        saveSuccess() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    if (this.ruleForm.option !== 15) {
                        this.ruleForm.remark = this.optionList.filter((v) => v.value === this.ruleForm.option)[0].example;
                    }
                    this.ruleForm.crm_token = this.$route.query.crm_token || ""
                    try {
                        this.isLoading = true;
                        this.data.profession = JSON.parse(JSON.stringify(this.ruleForm));
                        const { code } = await postClientInfo(this.data)
                        if (code === 200) {
                            this.$message.success("保存成功！");
                            this.isEdit = false;
                            let labels = this.optionList[this.ruleForm.option - 1];
                            this.option = labels.ind;
                            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'profession', data: JSON.parse(JSON.stringify(this.option)) })
                        }
                    } finally {
                        this.isLoading = false
                    }
                }
            })
        },
        cancel() {
            this.isEdit = false;
            this.ruleForm = JSON.parse(JSON.stringify(this.data.profession));
            let labels = this.optionList[this.ruleForm.option - 1];
            this.option = labels.ind;
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'profession', data: JSON.parse(JSON.stringify(this.option)) })
        },
        optionChange(e) {
            if (this.ruleForm.option === 15) {
                this.ruleForm.remark = '';
            }
        }
    },
}
</script>

<style lang="less" scoped>
</style>
