import { render, staticRenderFns } from "./ApplicantDetail.vue?vue&type=template&id=42889ebf&scoped=true&"
import script from "./ApplicantDetail.vue?vue&type=script&lang=js&"
export * from "./ApplicantDetail.vue?vue&type=script&lang=js&"
import style0 from "./ApplicantDetail.vue?vue&type=style&index=0&id=42889ebf&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42889ebf",
  null
  
)

export default component.exports