<template>
    <div class="pdf-bg10">
        <div v-if="residences.length > 0" v-for="(item, i) in residences" :key="i" :class="`residences${i+1}`">
            <div v-pdfbg:pdf-country="{ value: item.country, id: 'pdf-residences' }" :class="['country']">
                {{ item.country?item.country.split('-')[0]:'' }}
            </div>
            <div v-pdfbg:pdf-country="{ value: item.start_time, id: 'pdf-residences' }" :class="['start_time']">
                {{ item.start_time.replace(/-/g,'/') }}
            </div>
            <div v-pdfbg:pdf-country="{ value: item.end_time, id: 'pdf-residences' }" :class="['end_time']">
                {{item.end_time_checked ? '至今' : item.end_time.replace(/-/g,'/') }}
            </div>
        </div>
        <div class="language">
            <div v-if="language.level !== 6" class="language-Chinese language-type">
                <div v-pdfbg:language-top-content="{ value: '中文', id: 'language-top' }" class="language-name">中文
                </div>
                <div class="img1">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div class="img2">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div :class="[language.level === 6 ? 'img4': 'img3']">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
            </div>
            <div v-if="language.level !== 4 && language.level !== 5" class="language-English language-type">
                <div v-pdfbg:language-top-content="{ value: '英文', id: 'language-top' }" class="language-name">英文
                </div>
                <div class="img1">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div class="img2">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div :class="[language.level === 6 ? 'img3': 'img4']">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
            </div>
            <div v-if="language.level === 4" class="language-other language-type">
                <div v-pdfbg:language-top-content="{ value: language.name, id: 'language-top' }" class="language-name">
                    {{ language.name }}
                </div>
                <div class="img1">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div class="img2">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
                <div class="img4">
                    <img class="correct" src="@/assets/img/correct.png" alt="" />
                </div>
            </div>
        </div>
        <div v-pdfbg:pdf-profession-content="{ value: '', id: 'pdf-profession' }" class="profession1">
            <div class="profession">{{profession}}</div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['residences']),
        ...mapState('previewPdfGifted', ['profession']),
        ...mapState('previewPdfGifted', ['language'])
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.pdf-bg10{
    height: 1200px;
}
.red {
    color: red;
}
.correct{
    width: 10px;
}
.country{
    position: absolute;
    left: 30px;
    width: 420px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
}
.start_time{
    position: absolute;
    left: 451px;
    width: 124px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
}
.end_time{
    position: absolute;
    left: 576px;
    width: 111px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
}
.residences1{
    position: absolute;
    top: 114px;
}
.residences2{
    position: absolute;
    top: 144px;
}
.residences3{
    position: absolute;
    top: 175px;
}
.residences4{
    position: absolute;
    top: 205px;
}
.residences5{
    position: absolute;
    top: 236px;
}
.residences6{
    position: absolute;
    top: 266px;
}
.residences7{
    position: absolute;
    top: 297px;
}
.residences8{
    position: absolute;
    top: 327px;
}
.residences9{
    position: absolute;
    top: 358px;
}
.residences10{
    position: absolute;
    top: 389px;
}
.residences11{
    position: absolute;
    top: 419px;
}
.language{
    position: absolute;
    top: 572px;
    left: 30px;
    font-size: 18px;
    .language-type{
        height: 33px;
        display: flex;
        .language-name{
            line-height: 33px;
            margin-left: 3px;
            width: 365px;
        }
        .img1{
            position: absolute;
            left: 400px;
        }
        .img2{
            position: absolute;
            left: 474px;
        }
        .img3{
            position: absolute;
            left: 546px;
        }
        .img4{
            position: absolute;
            left: 618px;
        }
    }
}
.profession1{
    position: absolute;
    top: 818px;
    left: 6px;
    height: 145px;
    width: 692px;
    .profession{
        position: absolute;
        top: 110px;
        left: 104px;
    }
}
</style>