<template>
    <div class="pdf-other-bg4">
        <!-- 父母信息  -->
        <div  v-if="family.parent.length > 0" v-for="(item, i) in family.parent" :key="i">
            <!-- 父母姓名 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-parent' + i }" :class="['family-name',`parent-top${i+1}`]">
                {{ item.subset_name }}
            </div>
            <!-- 父母性别 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-parent' + i }" :class="['family-sex',`parent-top${i+1}`]">
                {{ i === 0 ? '男':'女' }}
            </div>
            <div v-if="!item.deceased">
                <!-- 父母生日及地点 -->
                <div v-pdfbg:subset_address="{ value: '', id: 'pdf-parent' + i }">
                    <div v-pdfbg:subset_birthday="{ value: item.birthday, id: 'pdf-parent' + i }" :class="['family-birthday',`birthday${i+1}`,`parent-top${i+1}`]">
                        <div>
                            {{ item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' + item.birthday.split('-')[2] + '日'}}
                        </div>
                        <div>
                            <!-- item.birth_place.foreign -->
                            {{ item.birth_place_aboard === 0 ? item.birth_place.area.join('').replace('市辖区','') : item.birth_place.foreign }}
                        </div>
                    </div>
                </div>
                <!-- 父母婚姻状况 -->
                <div v-pdfbg:subset_married="{ value: item.married, id: 'pdf-parent' + i }" :class="['family-married',`parent-top${i+1}`]">
                    {{ Number(item.married) | _married }}
                </div>
                <!-- 父母现居住国家 -->
                <div v-pdfbg:subset_live_country="{ value: item.live_country?item.live_country.split('-')[0]:'', id: 'pdf-parent' + i }" :class="['family-live_country',`parent-top${i+1}`]">
                    {{ item.live_country?item.live_country.split('-')[0]:'' }}
                </div>
                <!-- 父母香港身份证号 -->
                <div v-if="item.child_has_hk_id.has === 'Y'" v-pdfbg:subset_hk_identity_card="{ value: item.hk_identity_card, id: 'pdf-parent' + i }" :class="['family-hk_identity_card',`parent-top${i+1}`]">
                    {{ item.hk_identity_card }}
                </div>
            </div>
        </div>
        <!-- 配偶信息  -->
        <div v-if="user.married === 1 || user.married === 4">
            <!-- 配偶姓名 -->
            <div v-pdfbg:spouse_name="{ value: family.spouse.subset_name, id: 'pdf-spouse'}" :class="['family-name','spouse-top']">
                {{ family.spouse.subset_name }}
            </div>
            <!-- 配偶性别 -->
            <div v-pdfbg:spouse_sex="{ value: family.spouse.sex, id: 'pdf-spouse'}" :class="['family-sex','spouse-top']">
                {{ family.spouse.sex | _sex }}
            </div>
            <!-- 配偶生日及地点 -->
            <div v-pdfbg:spouse_address="{ value: family.spouse.birthday, id: 'pdf-spouse'}">
                <div v-pdfbg:spouse_birthday="{ value: family.spouse.birthday, id: 'pdf-spouse'}" :class="['family-birthday','spouse-birthday','spouse-top']">
                    <div v-if="family.spouse.birthday">
                        {{ family.spouse.birthday.split('-')[0] + '年' + family.spouse.birthday.split('-')[1] + '月' + family.spouse.birthday.split('-')[2] + '日'}}
                    </div>
                    <div>
                        <span v-if="family.spouse.birth_place_aboard === 0">{{family.spouse.birth_place.area.join('').replace('市辖区','')}}</span>
                        <span v-else>{{family.spouse.birth_place.foreign}}</span>
                    </div>
                </div>
            </div>
            <!-- 配偶婚姻状况 -->
            <div v-pdfbg:spouse_name="{ value: '已婚', id: 'pdf-spouse'}" :class="['family-married','spouse-top']">
                已婚
            </div>
            <!-- 配偶现居住国家 -->
            <!-- v-pdfbg:spouse_nationality="{ value: family.spouse.nationality, id: 'pdf-spouse'}" -->
            <!-- 判断是否随行 目前不随行时不定位 -->
            <div v-if="family.spouse.follow === 1">
                <!-- 判断是否同住 定位不同位置 -->
                <div v-if="family.spouse.nows === 1" v-pdfbg:spouse_isCohabitation="{ value: user.address.country.split('-')[0], id: 'pdf-spouse'}" :class="['family-live_country','spouse-top']">
                    <span>{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                </div>
                <div v-else v-pdfbg:spouse_nationality="{ value: family.spouse.address.country?user.address.country.split('-')[0]:'', id: 'pdf-spouse'}" :class="['family-live_country','spouse-top']">
                        {{ family.spouse.address.country?family.spouse.address.country.split('-')[0]:'' }}
                </div>
            </div>
            <div v-else>
                <div :class="['family-live_country','spouse-top']">
                    <span v-if="family.spouse.nows === 1">{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                    <span v-else>
                        {{ family.spouse.address.country?family.spouse.address.country.split('-')[0]:'' }}
                    </span>
                </div>
            </div>
            <!-- 配偶香港身份证号 -->
            <div v-if="family.spouse.child_has_hk_id.has === 'Y'" v-pdfbg:spouse_hk_identity_card="{ value: family.spouse.hk_identity_card, id: 'pdf-spouse'}" :class="['family-hk_identity_card','spouse-top']">
                {{ family.spouse.hk_identity_card }}
            </div>
        </div>
        <!-- 子女信息  -->
        <div v-pdfbg:children-top-name="{ value: '', id: 'children-top'}" class="children-names"></div>
        <div class="children"  v-if="family.children.length > 0" v-for="(item, i) in family.children" :key="'child' + i">
            <!-- 子女姓名 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-children' + i }" :class="['family-name',`children-top${i+1}`]">
                {{ item.subset_name }}
            </div>
            <!-- 子女生日及地点 -->
            <div v-pdfbg:subset_address="{ value: item.birthday, id: 'pdf-children' + i }">
                <div v-pdfbg:subset_birthday="{ value: item.birthday, id: 'pdf-children' + i }" :class="['family-birthday',`birthday${i+1}`,`children-top${i+1}`]">
                    <div>
                        {{ item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' + item.birthday.split('-')[2] + '日'}}
                    </div>
                    <div>
                        <!-- item.birth_place.foreign -->
                        {{ item.birth_place_aboard === 0 ? item.birth_place.area.join('').replace('市辖区','') : item.birth_place.foreign }}
                    </div>
                </div>
            </div>
            <!-- 子女性别 -->
            <div v-pdfbg:subset_sex="{ value: item.sex, id: 'pdf-children' + i }" :class="['family-sex',`children-top${i+1}`]">
                {{ item.sex | _sex }}
            </div>
            <!-- 子女婚姻状况 -->
            <div v-pdfbg:subset_married="{ value: item.married, id: 'pdf-children' + i }" :class="['family-married',`children-top${i+1}`]">
                {{ Number(item.married) | _married }}
            </div>
            <!-- 子女现居住国家 -->
            <!-- v-pdfbg:subset_live_country="{ value: item.nationality, id: 'pdf-children' + i }" -->
            <!-- 判断是否随行 目前不随行时不定位 -->
            <div v-if="item.follow === 1">
                <!-- 判断是否同住 定位不同位置 -->
                <div v-if="item.nows === 1" v-pdfbg:children_isCohabitation="{ value: user.address.country?user.address.country.split('-')[0]:'', id: 'pdf-children' + i}" :class="['family-live_country',`children-top${i+1}`]">
                    <span>{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                </div>
                <div v-else v-pdfbg:subset_live_country="{ value: item.address.country?user.address.country.split('-')[0]:'', id: 'pdf-children' + i}" :class="['family-live_country',`children-top${i+1}`]">
                        {{ item.address.country?item.address.country.split('-')[0]:'' }}
                </div>
            </div>
            <div v-else>
                <div :class="['family-live_country',`children-top${i+1}`]">
                    <span v-if="item.nows === 1">{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                    <span v-else>
                        {{ item.address.country?item.address.country.split('-')[0]:'' }}
                    </span>
                </div>
            </div>
            <!-- 香港身份证号 -->
            <div v-if="item.child_has_hk_id.has === 'Y'" v-pdfbg:subset_hk_identity_card="{ value: item.hk_identity_card, id: 'pdf-children' + i}" :class="['family-hk_identity_card',`children-top${i+1}`]">
                {{ item.hk_identity_card }}
            </div>
        </div>
        <!-- 保证人的陈述说明 -->
        <!-- 判断是否同住 定位不同位置 -->
        <div v-if="family.spouse.nows === 1" v-pdfbg:spouse_isCohabitation="{ value: user.address.country.split('-')[0], id: 'pdf-spouse'}" :class="['other-country']">
            <span class="other-country-Chinese">{{user.address.country?user.address.country.split('-')[0]:''}}</span>
            <span class="other-country-English">{{user.address.country?user.address.country.split('-')[1]:''}}</span>
        </div>
        <div v-else v-pdfbg:spouse_nationality="{ value: family.spouse.address.country?user.address.country.split('-')[0]:'', id: 'pdf-spouse'}" :class="['other-country']">
            <span class="other-country-Chinese">{{ family.spouse.address.country?family.spouse.address.country.split('-')[0]:'' }}</span>
            <span class="other-country-English">{{ family.spouse.address.country?family.spouse.address.country.split('-')[1]:'' }}</span>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import PdfFamily from './PdfFamily.vue'
export default {
    components:{
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['family']),
        ...mapState('previewPdfGifted', ['user'])
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.correct{
    width: 10px;
}
.red {
    color: red;
}
.parent-top1{
    position: absolute;
    top: 153px;
}
.parent-top2{
    position: absolute;
    top: 228px;
}
.spouse-top{
    position: absolute;
    top: 303px;
}
.children-top1{
    position: absolute;
    top: 390px;
}
.children-top2{
    position: absolute;
    top: 450px;
}
.children-top3{
    position: absolute;
    top: 510px;
}
.children-top4{
    position: absolute;
    top: 570px;
}
.children-top5{
    position: absolute;
    top: 630px;
}
.children-top6{
    position: absolute;
    top: 690px;
}
.family-name{
    left: 24px;
    width: 253px;
    height: 74px;
    padding: 13px 0 0 85px;
}
.family-sex{
    left: 278px;
    width: 45px;
    height: 74px;
    padding: 13px 0 0 5px;
}
.family-birthday{
    left: 324px;
    width: 99px;
    height: 74px;
    padding: 13px 0 0 3px;
}
.family-married{
    left: 424px;
    width: 85px;
    height: 74px;
    padding: 13px 0 0 3px;
}
.family-live_country{
    left: 510px;
    width: 134px;
    height: 74px;
    padding: 13px 0 0 3px;
}
.family-hk_identity_card{
    left: 646px;
    width: 146px;
    height: 74px;
    padding: 13px 0 0 3px;
}
.children-names{
    position: absolute;
    height: 294px;
    width: 75px;
    top: 390px;
    left: 28px;
}
.children{
    .family-name{
        left: 103px;
        width: 175px;
        height: 60px;
        padding: 3px 0 0 5px;
    }
    .family-sex{
        height: 60px;
        padding: 3px 0 0 5px;
    }
    .family-birthday{
        height: 60px;
        padding: 3px 0 0 3px;
    }
    .family-married{
        height: 60px;
        padding: 3px 0 0 3px;
    }
    .family-live_country{
        height: 60px;
        padding: 3px 0 0 3px;
    }
    .family-hk_identity_card{
        height: 60px;
        padding: 3px 0 0 3px;
    }
}
.other-country-Chinese{
    position: absolute;
    top: 876px;
    left: 391px;
}
.other-country-English{
    position: absolute;
    top: 925px;
    left: 496px;
}
</style>