<template>
    <div class="pdf-bg7">
        <!-- 教育经历 -->；
        <div :class="`education${i+1}`" v-if="education.length > 0" v-for="(item, i) in education" :key="'education' + i">
            <!-- 学位/资格 -->
            <div v-pdfbg:pdf-degree_type1="{ value: '', id: 'pdf-education' + i }">
                <div v-pdfbg:pdf-degree_type="{ value: item.degree_type + ' ' + item.professional, id: 'pdf-education' + i }" :class="['degree']">
                    {{ item.degree_type && !item.background.includes('无学位') ? item.degree_type : '' }}{{item.background.split('学位')[0]}}
                    <div>{{ item.professional}}</div>
                </div>
            </div>
            <!-- 颁授机构 -->
            <div v-pdfbg:pdf-type="{ value: '', id: 'pdf-education' + i }">
                <div v-pdfbg:pdf-college="{ value: item.college + ' ' + item.type, id: 'pdf-education' + i }" :class="['college']">
                    {{ item.college }}
                    <br>
                    {{item.type}}
                </div>
            </div>
            <!-- 国家/省/市 -->
            <div v-pdfbg:pdf-admission_time="{ value: '', id: 'pdf-education' + i }">
                <div v-pdfbg:pdf-graduate_time="{ value: '', id: 'pdf-education' + i }">
                    <div v-pdfbg:pdf-country="{ value: item.school_address.country + `-` + item.school_address.area[0] + `-` + item.school_address.area[1], id: 'pdf-education' + i }" :class="['country']">
                        <span v-if="item.school_address.country.includes('中国')">
                            {{ item.school_address.country.split('-')[0] + `/` + item.school_address.area[0] }}{{ item.school_address.area[1] == '市辖区'?'' : '/' + item.school_address.area[1] }}
                        </span>
                        <span v-else>
                            {{ item.school_address.country?item.school_address.country.split('-')[1]:'' + `/` + item.school_address.foreign }}
                        </span>
                        <div>
                            {{item.admission_time.slice(2).replace(/-/g,'.')}}/{{item.graduate_time.slice(2).replace(/-/g,'.')}}/
                            <span v-if="$dayjs(item.graduate_time) > $dayjs(new Date())">
                                <b>(在读)</b>
                            </span>
                            <span v-else>
                                {{item.graduate_time.split('-')[0]}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-pdfbg:pdf-match_listing="{ value: item.match_listing, id: 'pdf-education' + i }" :class="['match_listing']">
                <img v-if="item.match_listing === 1" class="correct" src="@/assets/img/correct.png" alt="">
            </div>
        </div>
        <!-- 工作经验 -->
        <div class="pdf-works">
            <div :class="[`works${i+1}`,'works']" v-if="works.works.length > 0 && item.is_synthetic === 1" v-for="(item, i) in works.works" :key="'works' + i">
                <!-- 所属机构性质 -->
                <div v-pdfbg:pdf-industry="{ value: '', id: 'pdf-works' + i }">
                    <div v-pdfbg:pdf-country="{ value: '', id: 'pdf-works' + i }">
                        <div v-pdfbg:pdf-company_name="{ value: item.enterprises_name || item.company_name, id: 'pdf-works' + i }" :class="['company']">
                            {{ item.enterprises_name || item.company_name}}
                            <div v-if="item.location_aboard === 1">
                                {{(item.location.country?item.location.country.split('-')[0]:'') + '/' + item.location.foreign}}
                            </div>
                            <div v-else>
                                {{(item.location.country?item.location.country.split('-')[0]:'') + '/' + item.location.area.join('/').replace('/市辖区','')}}
                            </div>
                            {{item.industry}}
                        </div>
                    </div>
                </div>
                <!-- 职位 -->
                <div v-pdfbg:pdf-professional_level="{ value: '', id: 'pdf-works' + i }">
                    <div v-pdfbg:pdf-position="{ value: item.position + ' ' + item.professional_level, id: 'pdf-works' + i }" :class="['position']">
                        {{ item.position}}
                        <br>
                        {{item.professional_level | professionalName}}
                    </div>
                </div>
                <!-- 职责性质 -->
                <div v-pdfbg:pdf-duty="{ value: item.duty, id: 'pdf-works' + i }" :class="['duty']">
                    {{ item.duty}}
                </div>
                <!-- 入职年月 -->
                <div v-pdfbg:pdf-entry_time="{ value: item.entry_time, id: 'pdf-works' + i }" :class="['entry_time']">
                    {{ item.entry_time.slice(2).replace(/-/g,'.')}}
                </div>
                <!-- 离职年月 -->
                <div v-pdfbg:pdf-departure_time="{ value: item.departure_time_checked ? '至今' : item.departure_time , id: 'pdf-works' + i }" :class="['departure_time']">
                    {{ item.departure_time_checked ? '至今' : item.departure_time.slice(2).replace(/-/g,'.') }}
                </div>
                <!-- 受聘年期 逻辑：（ (年-年)*12  + (月减月+1)  ） /12 -->
                <div :class="['Years_time']">
                    {{
                    item.departure_time_checked ?
                    `${(((Number(year) - Number(item.entry_time.split('-')[0]))*12+(Number(month)-Number(item.entry_time.split('-')[1])+1))/12).toString().match(/^\d+(?:\.\d{0,2})?/)}` :
                    `${(((Number(item.departure_time.split('-')[0]) - Number(item.entry_time.split('-')[0]))*12+(Number(Number(item.departure_time.split('-')[1] - item.entry_time.split('-')[1]))+1))/12).toString().match(/^\d+(?:\.\d{0,2})?/)}`
                    }}
                </div>
                <div v-pdfbg:pdf-match_listing="{ value: item.match_listing, id: 'pdf-works' + i }" :class="['match_listing','work_match_listing']">
                    <img v-if="item.match_listing === 1" class="correct" src="@/assets/img/correct.png" alt="">
                </div>
            </div>
        </div>
        <div class="scoreNum">
            {{Number(scoreNum.toString().match(/^\d+(?:\.\d{0,2})?/))}}
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            month:'',
            year:'',
            scoreNum:0
        }
    },
    filters:{
        professionalName(val) {
            switch (val) {
                case 5:
                    return '半专业人士'
                case 4:
                    return '专业人士'
                case 3:
                    return '初级经理人'
                case 2:
                    return '中级经理人'
                case 1:
                    return '高级经理人'
                case 6:
                    return '其他'
                default:
                    return '-';
            }
        },
    },
    computed: {
        ...mapState('previewPdfGifted', ['education']),
        ...mapState('previewPdfGifted', ['works'])
    },
    watch:{
        works:{
            handler(val){
                if(val && val.works){
                    this.scoreNum = 0;
                    val.works.forEach(arr => {
                        // 判断是否合成，如果合成算进总年限
                        if(arr.is_synthetic === 1){
                            var num = arr.departure_time_checked ?
                            `${((Number(this.year) - Number(arr.entry_time.split('-')[0]))*12+(Number(this.month)-Number(arr.entry_time.split('-')[1])+1))/12}` :
                            `${((Number(arr.departure_time.split('-')[0]) - Number(arr.entry_time.split('-')[0]))*12+(Number(Number(arr.departure_time.split('-')[1] - arr.entry_time.split('-')[1]))+1))/12}`
                            this.scoreNum = this.scoreNum + Number(num)
                        }
                    });
                }
            },
            deep:true,
            immediate: true,
        }
    },
    created(){
        var date = new Date();
        this.year = date.getFullYear();
        this.month = date.getMonth() + 1;
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.correct{
    width: 10px;
}
.pdf-bg7 {
    height: 1200px;
}
.red {
    color: red;
}
.degree{
    position: absolute;
    width: 210px;
    height: 50px;
    left: 45px;
}
.college{
    position: absolute;
    width: 146px;
    height: 50px;
    left: 278px;
}
.match_listing{
    position: absolute;
    width: 106px;
    height: 50px;
    left: 587px;
    padding: 17px 0 0 50px;
}
.work_match_listing{
    padding: 15px 0 0 50px;
}
.scoreNum{
    position: absolute;
    top: 967px;
    left: 496px;
    width: 90px;
    height: 27px;
    text-align: center;
    line-height: 27px;
}
.country{
    position: absolute;
    width: 127px;
    height: 50px;
    left: 448px;
}
.education1{
    position: absolute;
    top: 159px;
}

.education2{
    position: absolute;
    top: 212px;
}

.education3{
    position: absolute;
    top: 266px;
}

.education4{
    position: absolute;
    top: 319px;
}

.education5{
    position: absolute;
    top: 372px;
}
.company{
    position: absolute;
    width: 205px;
    height: 50px;
    left: 59px;
}
.position{
    position: absolute;
    width: 109px;
    height: 50px;
    left: 291px;
}
.duty{
    position: absolute;
    width: 82px;
    height: 50px;
    left: 411px;
}
.entry_time{
    position: absolute;
    width: 48px;
    height: 25px;
    left: 495px;
    padding-left: 3px;
}
.departure_time{
    position: absolute;
    width: 42px;
    height: 25px;
    left: 545px;
}
.Years_time{
    position: absolute;
    width: 88px;
    height: 26px;
    left: 497px;
    top: 25px;
    text-align: center;
}
.pdf-works{
    position: absolute;
    top: 593px;
}
.works{
    height: 53px;
    position: relative;
    padding-top: 2px;
}
// .works1{
//     top: 0;
//     width: 103px;
//     padding-top: 2px;
// }
// .works2{
//     position: absolute;
//     top: 648px;
//     padding-top: 2px;
// }
// .works3{
//     position: absolute;
//     top: 701px;
//     padding-top: 2px;
// }
// .works4{
//     position: absolute;
//     top: 754px;
//     padding-top: 2px;
// }
// .works5{
//     position: absolute;
//     top: 807px;
// }
// .works6{
//     position: absolute;
//     top: 860px;
// }
// .works7{
//     position: absolute;
//     top: 915px;
// }
</style>