<!--
 * @Author: zhai
 * @Description: 辅助资料
-->
<template>
    <div class="pdt-20">
        <p>1、您是否有行业内的专业资格证书？</p>
        <div>
            <span v-if="data.question.professional_qualification === 1">是</span>
            <span v-else-if="data.question.professional_qualification === 2">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p>2、您是否在期刊或杂志上发表过论文、文章、书籍等（与工作、资质有关的）？</p>
        <div>
            <span v-if="data.question.paper === 1">是</span>
            <span v-else-if="data.question.paper === 2">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p>3、您是否有大学奖学金证明、专利证书、获奖证书（工作单位颁发的可以）？</p>
        <div>
            <span v-if="data.question.scholarship === 1">是</span>
            <span v-else-if="data.question.scholarship === 2">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p>4、您是否被媒体采访过，专访您的文章被发表在杂志上或网络上？</p>
        <div>
            <span v-if="data.question.media_interview === 1">是</span>
            <span v-else-if="data.question.media_interview === 2">否</span>
            <span v-else>-</span>
        </div>
        <el-divider></el-divider>
        <p>5、请填写您的学业成绩、曾取得的奖项等</p>
        <div>{{data.question.academic_awards || "-"}}</div>
        <el-divider></el-divider>
        <p>6、请填写您在所属的专业界别内曾获取由国家或国际认可/著名的团体颁授的专业资格、曾取得的奖项等</p>
        <div>{{data.question.professional_awards || "-"}}</div>
        <el-divider></el-divider>
        <p>7、请填写其他能对申请带来帮助的重要资料（如：拥有有利于申请的社会任职、相关国际人脉/业务或随行人员所具备的优势点等）</p>
        <div>{{data.question.other_information || "-"}}</div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {

        }
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
    p {
        color: #999;
        margin-bottom: 20px;
    }
</style>
