<template>
    <div class="pdf-box pdf-wrap">
        <div v-if="activeName === 'user'" class="pageScroll">
            <span :class="pageSize981 == index?'active':''" v-for="(item,index) in 11" @click="anchorPoint(index>6?index+2:index+1)">
                {{index +1}}页
            </span>
        </div>
        <div v-else class="pageScroll">
            <span :class="pageSize997 == index?'active':''" v-for="(item,index) in 4" @click="anchorPoint(index+1,'other')">
                {{index +1}}页
            </span>
        </div>
        <div class="pdf-box">
            <div class="tabs-top">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <!-- 981主申人 -->
                    <el-tab-pane :label="'981-' + user.username + '(主申人)'" name="user"></el-tab-pane>
                    <!-- 977配偶 -->
                    <el-tab-pane v-if="family.spouse.follow === 1" :label="'997-' + family.spouse.subset_name+ '(配偶)'" name="spouse"></el-tab-pane>
                    <!-- 977子女 -->
                    <el-tab-pane v-if="family.children.length > 0 && family.children[index].follow === 1" v-for="(item,index) in family.children" :key="index" :label="'997-' + item.subset_name + '(子女)'" :name="'children'+index"></el-tab-pane>
                </el-tabs>
            </div>
            <!-- 981 -->
            <PdfUser v-if="activeName === 'user'"></PdfUser>
            <!-- 997 -->
            <PdfOtherInfo :activeName="activeName" :data="otherData" v-else></PdfOtherInfo>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PdfUser from './981C/PdfUser.vue'
import PdfOtherInfo from './997C/PdfOtherInfo.vue'
export default {
    components:{
        PdfUser,
        PdfOtherInfo
    },
    data() {
        return {
            activeName: 'user',
            pageSize997:0,
            pageSize981:0,
            otherData:100//977数据
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['user']),
        ...mapState('previewPdfGifted', ['family'])
    },
    methods: {
        anchorPoint(index,str) {
            this.$nextTick(() => {
                let pageId = null;
                if(str === 'other'){
                    pageId = document.querySelector(`.pdf-other-bg${index}`)
                }else{
                    pageId = document.querySelector(`.pdf-bg${index}`)
                }
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        },
        handleClick(val){
            // 切换默认到第一页
            this.pageSize997 = 0;
            this.pageSize981 = 0;
            let NewArr = val.name.split('children') //获取子女的下标
            // 传下标给子女页面
            if(NewArr.length>1){
                this.otherData = Number(NewArr[1])
            }else{
                this.otherData = 100
            }
        },
        pageScroll997(num){
            this.pageSize997 = num;
        },
        pageScroll981(num){
            this.pageSize981 = num;
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
.pdf-wrap{
    padding-left: 60px;
}
.pdf-box {
    height: 100%;
    color: #4c4c4c;
    font-size: 12px;
    flex: 1;
    position: relative;
    background-color: #fff;
    ::v-deep .tabs-top{
        width: 100%;
        .el-tabs__header{
            margin-bottom: 0;
        }
    }
}

.pageScroll{
    position: absolute;
    top: 40px;
    left:0;
    z-index: 10;
    span{
        width: 56px;
        height: 34px;
        border: 1px solid #409eff;
        border-radius: 4px;
        display: block;
        text-align: center;
        line-height: 32px;
        color: #409eff;
        margin-bottom: 6px;
        cursor: pointer;
    }
    .active{
        background-color: #409eff;
        color: #fff;
    }
}
</style>