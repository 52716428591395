<template>
    <div class="pdf-other-bg1">
        <!-- 子女姓名 -->
        <div v-pdfbg:subset_name="{ value: family.children[dataNum].subset_name, id: 'pdf-children' + dataNum}" :class="['other-name','absolute']">
            {{ family.children[dataNum].subset_name }}
        </div>
        <!-- 配偶曾用名 -->
        <div v-pdfbg:subset-used_name="{ value: family.children[dataNum].used_name, id: 'pdf-children' + dataNum}" :class="['other-used_name','absolute']">
            {{ family.children[dataNum].used_name }}
        </div>
        <!-- 配偶姓名拼音 -->
        <div v-pdfbg:subset_name_pinyin="{ value: family.children[dataNum].subset_name_pinyin.family_name, id: 'pdf-children' + dataNum}" :class="['other-name-pinyin','absolute']">
            <div class="given_name_div" v-for="(givenName,index) in family.children[dataNum].subset_name_pinyin.family_name" :key="index">{{givenName}}</div>
        </div>
        <div v-pdfbg:subset_name_pinyin="{ value: family.children[dataNum].subset_name_pinyin.given_name, id: 'pdf-children' + dataNum}" :class="['other-name-pinyin2','absolute']">
            <div class="given_name_div" v-for="(givenName2,index) in family.children[dataNum].subset_name_pinyin.given_name" :key="index">{{givenName2}}</div>
        </div>
        <!-- 配偶性别 -->
        <div v-pdfbg:subset_sex="{ value: family.children[dataNum].subset_name, id: 'pdf-children' + dataNum}" :class="['other-sex','absolute']">
            <img :class="['correct',family.children[dataNum].sex === 1?'boy':'girl']" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 配偶出生年月 -->
        <div v-pdfbg:subset_birthday="{ value: family.children[dataNum].birthday, id: 'pdf-children' + dataNum}" :class="['other-birthday','absolute']">
            <ul>
                <li class="birthday-year">{{family.children[dataNum].birthday.split('-')[0]}}</li>
                <li class="birthday-month">{{family.children[dataNum].birthday.split('-')[1]}}</li>
                <li class="birthday-day">{{family.children[dataNum].birthday.split('-')[2]}}</li>
            </ul>
        </div>
        <!-- 配偶出生地址 -->
        <div v-pdfbg:subset_address="{ value: family.children[dataNum].birth_place_aboard === 0 ? family.children[dataNum].birth_place.area.join('').replace('市辖区','') : family.children[dataNum].birth_place.foreign, id: 'pdf-children' + dataNum}" :class="['other-address','absolute']">
            <div>
                {{ family.children[dataNum].birth_place_aboard === 0 ? family.children[dataNum].birth_place.area.join('').replace('市辖区','') : family.children[dataNum].birth_place.foreign }}
            </div>
        </div>
        <!-- 配偶国籍 -->
        <div v-pdfbg:subset_nationality="{ value: family.children[dataNum].nationality, id: 'pdf-children' + dataNum}" :class="['other-nationality','absolute']">
            <div>
                {{ family.children[dataNum].nationality?family.children[dataNum].nationality.split('-')[0]:'' }}
            </div>
        </div>
        <!-- 配偶婚姻状况 -->
        <div class="user_married" v-pdfbg:subset_married="{  id: 'pdf-children' + dataNum }">
            <img :class="['correct',`married${family.children[dataNum].married}`]" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 配偶旅行证件类别 大陆为港澳通行证 国外和香港澳门为护照 -->
        <div>
            <div v-if="family.children[dataNum].nationality == '中国-China'">
                <div v-pdfbg:subset_certificates_name="{ value: '港澳通行证', id: 'pdf-children' + dataNum}" class="certificates_name absolute">港澳通行证</div>
                <!-- 证件号码  -->
                <div v-pdfbg:subset_certificates_number="{ value: family.children[dataNum].certificates.hk_macao_pass.number, id: 'pdf-children' + dataNum}"
                    class="certificates_number absolute">
                    {{family.children[dataNum].certificates.hk_macao_pass.number}}
                </div>
                <!-- 签发地点 -->
                <div v-pdfbg:subset_certificates_issue_at="{ value: family.children[dataNum].certificates.hk_macao_pass.issue_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_at absolute">
                    {{family.children[dataNum].certificates.hk_macao_pass.issue_at}}
                </div>
                <!-- 签发日期  -->
                <div v-pdfbg:subset_certificates_issue_date_start_at="{ value: family.children[dataNum].certificates.hk_macao_pass.issue_date_start_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_date_start_at absolute">
                    <ul>
                        <li class="year">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_start_at.split('-')[0]}}</li>
                        <li class="month">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_start_at.split('-')[1]}}</li>
                        <li class="day">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_start_at.split('-')[2]}}</li>
                    </ul>
                </div>
                <!-- 届满日期  -->
                <div v-pdfbg:subset_certificates_issue_date_end_at="{ value: family.children[dataNum].certificates.hk_macao_pass.issue_date_end_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_date_end_at absolute">
                    <ul>
                        <li class="year">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_end_at.split('-')[0]}}</li>
                        <li class="month">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_end_at.split('-')[1]}}</li>
                        <li class="day">{{family.children[dataNum].certificates.hk_macao_pass.issue_date_end_at.split('-')[2]}}</li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div v-pdfbg:subset_certificates_name2="{ value: '护照', id: 'pdf-children' + dataNum}" class="certificates_name absolute">护照</div>
                <!-- 证件号码  -->
                <div v-pdfbg:subset_certificates_number2="{ value: family.children[dataNum].certificates.passport.number, id: 'pdf-children' + dataNum}"
                    class="certificates_number absolute">
                    {{family.children[dataNum].certificates.passport.number}}
                </div>
                <!-- 签发地点 -->
                <div v-pdfbg:subset_certificates_issue_at2="{ value: family.children[dataNum].certificates.passport.issue_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_at absolute">
                    {{family.children[dataNum].certificates.passport.issue_at}}
                </div>
                <!-- 签发日期  -->
                <div v-pdfbg:subset_certificates_issue_date_start_at2="{ value: family.children[dataNum].certificates.passport.issue_date_start_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_date_start_at absolute">
                    <ul>
                        <li class="year">{{family.children[dataNum].certificates.passport.issue_date_start_at.split('-')[0]}}</li>
                        <li class="month">{{family.children[dataNum].certificates.passport.issue_date_start_at.split('-')[1]}}</li>
                        <li class="day">{{family.children[dataNum].certificates.passport.issue_date_start_at.split('-')[2]}}</li>
                    </ul>
                </div>
                <!-- 届满日期  -->
                <div v-pdfbg:subset_certificates_issue_date_end_at2="{ value: family.children[dataNum].certificates.passport.issue_date_end_at, id: 'pdf-children' + dataNum}"
                    class="certificates_issue_date_end_at absolute">
                    <ul>
                        <li class="year">{{family.children[dataNum].certificates.passport.issue_date_end_at.split('-')[0]}}</li>
                        <li class="month">{{family.children[dataNum].certificates.passport.issue_date_end_at.split('-')[1]}}</li>
                        <li class="day">{{family.children[dataNum].certificates.passport.issue_date_end_at.split('-')[2]}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 现居住地址  -->
        <div class="certificates_address absolute">
            香港湾仔告士打道80号 17/F <br>
            银河国际移民顾问有限公司
        </div>
        <!-- 固定地址 -->
        <div v-if="family.children[dataNum].nows === 1">
            <span v-if="user.address.country" class="address" v-pdfbg:user_address="{ value: user.address.country + '-' + (user.address.country.includes('中国') ? (user.address.area.join('') + user.address.details) : user.address.foreign), id: 'pdf-user' }" :class="['other-country','absolute']">
                {{(user.address.country.includes('中国') ? (user.address.area.join('').replace('市辖区','') + user.address.details) : user.address.foreign)}}
            </span>
        </div>
        <div v-else v-pdfbg:subset_live_country="{ value: family.children[dataNum].nationality, id: 'pdf-children' + dataNum}" :class="['other-country','absolute']">
            <div>
                <span v-if="family.children[dataNum].address.country && family.children[dataNum].address.country.includes('中国')">{{ family.children[dataNum].address.area[0] + family.children[dataNum].address.area[1] + ' - ' + family.children[dataNum].address.details }}</span>
                <span v-else>{{ family.children[dataNum].address.foreign }}</span>
            </div>
        </div>
        <!-- 香港身份证号码 -->
        <div v-if="family.children[dataNum].child_has_hk_id.has == 'Y'" v-pdfbg:subset_hk_identity_card="{ value: family.children[dataNum].hk_identity_card, id: 'pdf-children' + dataNum}" :class="['other-hk_identity_card','absolute']">
            <span v-for="(item,index) in family.children[dataNum].hk_identity_card" :key="index" :class="`identity${index}`">{{item}}</span>
        </div>
        <!-- 内地身份证号码 -->
        <div v-pdfbg:subset_cn_identity_card="{ value: family.children[dataNum].certificates.cn_identity_card.number, id: 'pdf-children' + dataNum}" :class="['other-cn_identity_card','absolute']">
            {{family.children[dataNum].certificates.cn_identity_card.number}}
        </div>
        <!-- 电邮地址 -->
        <div class="info_em">
            <div class="info_em_div" v-for="(item,index) in info_em">{{item}}</div>
        </div>
        <!-- 联络号码 -->
        <div class="user_mobile">
            852-69534057
        </div>
        <!-- 现定居国家 -->
        <div v-if="family.children[dataNum].nows === 1">
            <span v-if="user.address.country" class="address" v-pdfbg:user_address="{ value: user.address.country + '-' + (user.address.country.includes('中国') ? (user.address.area.join('') + user.address.details) : user.address.foreign), id: 'pdf-user' }" :class="['other-country2','absolute']">
                {{user.address.country.split('-')[0]}}
            </span>
        </div>
        <div v-else v-pdfbg:subset_live_country="{ value: family.children[dataNum].nationality, id: 'pdf-children' + dataNum}" :class="['other-country2','absolute']">
            <div>
                <span v-if="family.children[dataNum].address.country && family.children[dataNum].address.country.includes('中国')">{{ family.children[dataNum].address.country.split('-')[0] }}</span>
                <span v-else>{{ family.children[dataNum].address.country.split('-')[0] }}</span>
            </div>
        </div>
        <!-- 是否有永久居留身份 -->
        <div :class="['other-residence','absolute']">
            <img :class="['correct']" src="@/assets/img/correct.png" alt="">
        </div>
        <!-- 居留时间 -->
        <div v-pdfbg:subset_permanent_reside_year="{ value: family.children[dataNum].permanent_reside_year, id: 'pdf-children' + dataNum}" :class="['other-reside_year','absolute']">
            <div>
                <div class="permanent_reside_year">{{ family.children[dataNum].permanent_reside_year<10?('0'+family.children[dataNum].permanent_reside_year):family.children[dataNum].permanent_reside_year }}</div>
                <div class="permanent_reside_month">{{ family.children[dataNum].permanent_reside_month<10?('0'+family.children[dataNum].permanent_reside_month):family.children[dataNum].permanent_reside_month }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    components:{
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                }
            }
        },
        dataNum:{
            type:Number
        }
    },
    data() {
        return {
            info_em:'dochk@galaxyoversea.com',
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['family']),
        ...mapState('previewPdfGifted', ['user'])
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.correct{
    width: 10px;
}
.red {
    color: red;
}
.absolute{
    position: absolute;
}
.given_name_div{
    display: inline-block;
    width: 20.3px;
    text-align: center;
}
.other-name{
    left: 25px;
    top: 319px;
    width: 348px;
    height: 26px;
    padding: 5px 0 0 155px;
}
.other-used_name{
    left: 25px;
    top: 409px;
    width: 767px;
    height: 32px;
    padding: 8px 0 0 83px;
}
.other-name-pinyin{
    left: 25px;
    top: 349px;
    width: 766px;
    height: 26px;
    padding: 4px 0 0 115px;
}
.other-name-pinyin2{
    left: 25px;
    top: 380px;
    width: 766px;
    height: 26px;
    padding: 4px 0 0 115px;
}
.other-sex{
    left: 25px;
    top: 442px;
    width: 222px;
    height: 48px;
    padding-top: 15px;
    .boy{
        margin-left: 65px;
    }
    .girl{
        margin-left: 145px;
    }
}
.other-birthday{
    left: 245px;
    top: 441px;
    width: 285px;
    height: 49px;
    .birthday-year{
        position: absolute;
        top: 11px;
        left: 200px;
        letter-spacing: 13px;
    }
    .birthday-month{
        position: absolute;
        top: 11px;
        left:146px;
        letter-spacing: 13px;
    }
    .birthday-day{
        position: absolute;
        top: 11px;
        left: 91px;
        letter-spacing: 13px;
    }
}
.other-address{
    left: 531px;
    top: 441px;
    width: 263px;
    height: 49px;
    padding: 14px 0 0 70px;
}
.other-nationality{
    left: 26px;
    top: 491px;
    width: 220px;
    height: 26px;
    padding: 5px 0 0 70px;
}
.user_married{
    position: absolute;
    top: 491px;
    left: 245px;
    width: 548px;
    height: 26px;
    .correct{
        position: absolute;
        margin-top: 7px;
    }
    .married2{
        left: 83px;
    }
    .married1{
        left: 207px;
    }
    .married4{
        left: 359px;
    }
    .married3{
        left: 281px;
    }
    .married5{
        left: 442px;
    }
}
.certificates_name{
    left: 27px;
    top: 518px;
    width: 300px;
    height: 27px;
    padding: 5px 0 0 122px;
}
.certificates_number{
    left: 340px;
    top: 518px;
    width: 300px;
    height: 27px;
    padding: 5px 0 0 122px;
    letter-spacing: 12.5px;
}
.certificates_issue_at{
    left: 27px;
    top: 545px;
    width: 198px;
    height: 46px;
    padding: 15px 0 0 79px;
}
.certificates_issue_date_start_at{
    left: 227px;
    top: 545px;
    width: 275px;
    height: 46px;
    .year{
        position: absolute;
        top: 10px;
        left: 193px;
        letter-spacing: 13px;
    }
    .month{
        position: absolute;
        top: 10px;
        left:138px;
        letter-spacing: 13px;
    }
    .day{
        position: absolute;
        top: 10px;
        left: 84px;
        letter-spacing: 13px;
    }
}
.certificates_issue_date_end_at{
    left: 504px;
    top: 545px;
    width: 288px;
    height: 46px;
    .year{
        position: absolute;
        top: 10px;
        left: 193px;
        letter-spacing: 13px;
    }
    .month{
        position: absolute;
        top: 10px;
        left:138px;
        letter-spacing: 13px;
    }
    .day{
        position: absolute;
        top: 10px;
        left: 84px;
        letter-spacing: 13px;
    }
}
.certificates_address{
    left: 246px;
    top: 599px;
    width: 288px;
    height: 46px;
    line-height: 40px;
}
.other-country{
    left: 246px;
    top: 742px;
    width: 288px;
    height: 46px;
}
.other-hk_identity_card{
    left: 27px;
    top: 845px;
    width: 430px;
    height: 32px;
    letter-spacing: 12.5px;
    padding: 7px 0 0 212px;
    .identity0{
        margin-right: 20px;
    }
    .identity6{
        margin-right: 20px;
    }
}
.other-cn_identity_card{
    left: 458px;
    top: 845px;
    width: 334px;
    height: 32px;
    padding: 7px 0 0 165px;
}
.info_em{
    position: absolute;
    width: 767px;
    height: 31px;
    top: 877px;
    left: 25px;
    box-sizing: border-box;
    padding-left: 122px;
    padding-top: 7px;
    .info_em_div{
        width: 17.5px;
        display: inline-block;
        text-align: center;
    }
}
.user_mobile{
    position: absolute;
    top: 914px;
    left: 141px;
}
.other-country2{
    position: absolute;
    top: 936px;
    left: 27px;
    width: 255px;
    height: 28px;
    padding: 5px 0 0 147px;
}
.other-residence{
    top: 940px;
    left: 687px;
}
.other-reside_year{
    top: 964px;
    left: 25px;
    width: 767px;
    height: 30px;
    .permanent_reside_year{
        position: absolute;
        top: 6px;
        right: 456px;
        letter-spacing: 12px;
    }
    .permanent_reside_month{
        position: absolute;
        top: 6px;
        right: 317px;
        letter-spacing: 12px;
    }
}
</style>