<template>
    <div style="overflow-y: auto;height: calc(100% - 42px);">
        <!-- 主申请人 -->
        <div class="pdf-bg1" v-if="Object.keys(user).length > 0">
            <!-- 姓名 -->
            <div class="username" v-pdfbg:username="{ value: user.username, id: 'pdf-user' }">{{ user.username }}</div>
            <!-- 婚前名字 -->
            <div class="used_name" v-pdfbg:used_name="{ value: user.used_name, id: 'pdf-user' }">{{ user.used_name }}</div>
            <!-- 曾用名 -->
            <div class="used_name2" v-pdfbg:used_name="{ value: user.used_name, id: 'pdf-user' }">{{ user.used_name }}</div>
            <!-- 姓名拼音 -->
            <div class="family_name" v-pdfbg:username_pinyin="{ value: user.username_pinyin.family_name, id: 'pdf-user' }">{{user.username_pinyin.family_name}}</div>
            <div class="given_name" v-pdfbg:username_pinyin="{ value: user.username_pinyin.given_name, id: 'pdf-user' }">
                <div class="given_name_div" v-for="(givenName,index) in user.username_pinyin.given_name" :key="index">{{givenName}}</div>
            </div>
            <!-- 性别 勾选-->
            <div class="user_sex" v-pdfbg:user_sex="{ value: user.sex, id: 'pdf-user' }">
                <img :class="['correct',user.sex === 1?'boy':'girl']" src="@/assets/img/correct.png" alt="">
            </div>
            <!-- 生日 -->
            <div class="birthday" v-pdfbg:user_birthday="{ value: user.birthday, id: 'pdf-user' }">
                <ul>
                    <li class="birthday-year">{{user.birthday.split('-')[0]}}</li>
                    <li class="birthday-month">{{user.birthday.split('-')[1]}}</li>
                    <li class="birthday-day">{{user.birthday.split('-')[2]}}</li>
                </ul>
            </div>
            <!-- 婚姻状况 勾选 -->
            <div class="user_married" v-pdfbg:user_married="{ value: user.married, id: 'pdf-user' }">
                <img :class="['correct',`married${user.married}`]" src="@/assets/img/correct.png" alt="">
            </div>
            <!-- 国籍 -->
            <div class="user_nationality" v-pdfbg:user_nationality="{ value: user.nationality?user.nationality.split('-')[0]:'', id: 'pdf-user' }">
                {{ user.nationality?user.nationality.split('-')[0]:'' }}
            </div>
            <!-- 出生地点 -->
            <div class="birth_place_aboard" v-pdfbg:birth_place_aboard="{ value: user.birth_place_aboard === 0 ? user.birth_place.area.join('') : user.birth_place.foreign, id: 'pdf-user' }">
                {{user.birth_place_aboard === 0 ? user.birth_place.area.join('').replace('市辖区','') : user.birth_place.foreign}}
            </div>
            <!-- 现居住地址 -->
            <div class="address1">
                香港灣仔告士打道80號 17/F銀河國際移民顧問有限公司
            </div>
            <!-- 固定地址 -->
            <div v-if="user.address.country" class="address" v-pdfbg:user_address="{ value: user.address.country + '-' + (user.address.country.includes('中国') ? (user.address.area.join('') + user.address.details) : user.address.foreign), id: 'pdf-user' }">
                {{user.address.country.split('-')[0] + '-' + (user.address.country.includes('中国') ? (user.address.area.join('').replace('市辖区','') + user.address.details) : user.address.foreign)}}
            </div>
            <!-- 现居住地址国家 -->
            <div v-if="user.address.country" class="address_country" v-pdfbg:user_address="{ value: user.address.country + '-' + (user.address.country.includes('中国') ? (user.address.area.join('') + user.address.details) : user.address.foreign), id: 'pdf-user' }">
                {{user.address.country.split('-')[0]}}
            </div>
            <!-- 港澳通行证 -->
            <div v-if="user.address.country == '中国-China' && user.nationality == '中国-China'">
                <div class="certificates_name" v-pdfbg:hk_macao_pass_name="{ value: '港澳通行证', id: 'pdf-user' }">港澳通行证</div>
                <div class="certificates_hk_number" v-pdfbg:hk_macao_pass_number="{ value: user.certificates.hk_macao_pass.number, id: 'pdf-user' }">
                    {{user.certificates.hk_macao_pass.number}}
                </div>
                <!-- 港澳通行证 - 签发地址 -->
                <div class="certificates_hk_issue_at" v-pdfbg:hk_macao_pass_issue_at="{ value: user.certificates.hk_macao_pass.issue_at, id: 'pdf-user' }">
                    {{user.certificates.hk_macao_pass.issue_at}}
                </div>
                <!-- 港澳通行证 - 签发日期 -->
                <div class="certificates_hk_date" v-pdfbg:hk_macao_pass_issue_date_start_at="{ value: user.certificates.hk_macao_pass.issue_date_start_at, id: 'pdf-user' }">
                    <ul>
                        <li class="certificates_hk_date-year">{{user.certificates.hk_macao_pass.issue_date_start_at.split('-')[0]}}</li>
                        <li class="certificates_hk_date-month">{{user.certificates.hk_macao_pass.issue_date_start_at.split('-')[1]}}</li>
                        <li class="certificates_hk_date-day">{{user.certificates.hk_macao_pass.issue_date_start_at.split('-')[2]}}</li>
                    </ul>
                </div>
                <!-- 港澳通行证 - 届满日期 -->
                <div class="certificates_hk_end_date" v-pdfbg:hk_macao_pass_issue_date_end_at="{ value: user.certificates.hk_macao_pass.issue_date_end_at, id: 'pdf-user' }">
                    <ul>
                        <li class="certificates_hk_end_date-year">{{user.certificates.hk_macao_pass.issue_date_end_at.split('-')[0]}}</li>
                        <li class="certificates_hk_end_date-month">{{user.certificates.hk_macao_pass.issue_date_end_at.split('-')[1]}}</li>
                        <li class="certificates_hk_end_date-day">{{user.certificates.hk_macao_pass.issue_date_end_at.split('-')[2]}}</li>
                    </ul>
                </div>
            </div>
            <!-- 护照 -->
            <div v-else>
                <div class="certificates_name" v-pdfbg:passport_name="{ value: '护照', id: 'pdf-user' }">护照</div>
                <div class="certificates_hk_number" v-pdfbg:passport_number="{ value: user.certificates.passport.number, id: 'pdf-user' }">
                    {{user.certificates.passport.number}}
                </div>
                <!-- 护照 - 签发地址 -->
                <div class="certificates_hk_issue_at" v-pdfbg:passport_issue_at="{ value: user.certificates.passport.issue_at, id: 'pdf-user' }">
                    {{user.certificates.passport.issue_at}}
                </div>
                <!-- 护照 - 签发日期 -->
                <div class="certificates_hk_date" v-pdfbg:passport_issue_date_start_at="{ value: user.certificates.passport.issue_date_start_at, id: 'pdf-user' }">
                    <ul>
                        <li class="certificates_hk_date-year">{{user.certificates.passport.issue_date_start_at.split('-')[0]}}</li>
                        <li class="certificates_hk_date-month">{{user.certificates.passport.issue_date_start_at.split('-')[1]}}</li>
                        <li class="certificates_hk_date-day">{{user.certificates.passport.issue_date_start_at.split('-')[2]}}</li>
                    </ul>
                </div>
                <!-- 护照 - 届满日期 -->
                <div class="certificates_hk_end_date" v-pdfbg:passport_issue_date_end_at="{ value: user.certificates.passport.issue_date_end_at, id: 'pdf-user' }">
                    <ul>
                        <li class="certificates_hk_end_date-year">{{user.certificates.passport.issue_date_end_at.split('-')[0]}}</li>
                        <li class="certificates_hk_end_date-month">{{user.certificates.passport.issue_date_end_at.split('-')[1]}}</li>
                        <li class="certificates_hk_end_date-day">{{user.certificates.passport.issue_date_end_at.split('-')[2]}}</li>
                    </ul>
                </div>
            </div>
            <!-- 身份证号 -->
            <div class="certificates_number" v-pdfbg:cn_identity_card_number="{ value: user.certificates.cn_identity_card.number, id: 'pdf-user' }">
                {{user.certificates.cn_identity_card.number}}
            </div>
            <!-- 港澳通行证 - 签发地址 -->
            <div class="certificates_cn_issue_at" v-pdfbg:cn_identity_card_issue_at="{ value: user.certificates.cn_identity_card.issue_at, id: 'pdf-user' }">
                {{user.certificates.cn_identity_card.issue_at}}
            </div>
            <!-- 身份证号签发日期 -->
            <div class="certificates_date" v-pdfbg:cn_identity_card_issue_date_start_at="{ value: user.certificates.cn_identity_card.issue_date_start_at, id: 'pdf-user' }">
                <ul>
                    <li class="certificates_date-year">{{user.certificates.cn_identity_card.issue_date_start_at.split('-')[0]}}</li>
                    <li class="certificates_date-month">{{user.certificates.cn_identity_card.issue_date_start_at.split('-')[1]}}</li>
                    <li class="certificates_date-day">{{user.certificates.cn_identity_card.issue_date_start_at.split('-')[2]}}</li>
                </ul>
            </div>
            <!-- 手机号码 -->
            <div class="user_mobile">
                852-69534057
            </div>
            <!-- 电邮地址 -->
            <div class="info_em">
                <div class="info_em_div" v-for="(item,index) in info_em">{{item}}</div>
            </div>
            <!-- 是否拥有现居住国家的永久居住身份 勾选 -->
            <div class="user_live">
                <img class="correct" src="@/assets/img/correct.png" alt="">
            </div>
        </div>
        <!-- 配偶信息 -->
        <PdfFamily />
        <!-- 基本资格 -->
        <PdfBasicQualifications />
        <PdfScore />
        <div class="pdf-bg6">
            <div v-pdfbg:pdf-spouse-score="{ id: 'pdf-spouse' }" :class="['spouse_bg6',family.spouse.follow === 1?'spouse_follow':'spouse_noFollow']">
                <img :class="['correct',family.spouse.follow !== 1?'spouse-follow':family.spouse.has_degree == 1?'':'follow_correct']" src="@/assets/img/correct.png" alt="">
            </div>
        </div>
        <!-- 教育经历 -->
        <PdfWord />
        <!-- <div class="pdf-bg8"></div> -->
        <div class="pdf-bg9"></div>
        <PdfResidences />
        <div class="pdf-bg11"></div>
        <div class="pdf-bg12"></div>
        <div ref="otherPdf"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import PdfFamily from './PdfFamily.vue'
import PdfBasicQualifications from './PdfBasicQualifications.vue'
import PdfWord from './PdfWord.vue'
import PdfResidences from './PdfResidences.vue'
import PdfScore from './PdfScore.vue'
export default {
    components:{
        PdfFamily,
        PdfWord,
        PdfBasicQualifications,
        PdfResidences,
        PdfScore
    },
    data() {
        return {
            info_em:'dochk@galaxyoversea.com',
            activeName: 'first'
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['user']),
        ...mapState('previewPdfGifted', ['family'])
    },
    mounted(){
        window.addEventListener('scroll',this.handleScroll,true)
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        handleScroll() {
            let height = 13241-(this.$refs.otherPdf.getBoundingClientRect().top)
            let hh = parseInt(height/1190)
            this.$parent.pageScroll981(hh)
        },
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
.pdf-box {
    height: 100%;
    color: #4c4c4c;
    font-size: 12px;
    flex: 1;
    padding-left: 30px;
    overflow-y: auto;
    position: relative;
    background-color: #fff;
}

.pdf-bg(@i) when (@i > 0) {
    .pdf-bg@{i} {
        position: relative;
        background: url('https://upload.cdn.galaxy-immi.com/public/img/981/ID981B-@{i}.jpg?ver=202301061052') no-repeat;
        background-size: 100%;
        font-weight: @i;
    }
    .pdf-bg(( @i - 1 ));
}

.pdf-bg(12);

.pageScroll{
    position: absolute;
    top: 0px;
    left:0;
    z-index: 10;
    span{
        width: 56px;
        height: 34px;
        border: 1px solid #409eff;
        border-radius: 4px;
        display: block;
        text-align: center;
        line-height: 32px;
        color: #409eff;
        margin-bottom: 6px;
        cursor: pointer;
    }
}
.correct{
    width: 10px;
}
.pdf-bg5,.pdf-bg6,.pdf-bg8,.pdf-bg9,.pdf-bg11,.pdf-bg12 {
    height: 1200px;
}
.pdf-bg1 {
    height: 1200px;
    .username {
        position: absolute;
        top: 322px;
        left: 5px;
        width: 457px;
        height: 40px;
        padding: 20px 9px 0;
    }
    .used_name{
        position: absolute;
        top: 322px;
        left: 462px;
        width: 325px;
        height: 40px;
        padding: 20px 9px 0;
    }
    .used_name2{
        position: absolute;
        top: 425px;
        left: 5px;
        width: 782px;
        height: 39px;
        padding: 20px 9px 0;
    }
    .family_name {
        position: absolute;
        top: 362px;
        left: 5px;
        width: 768px;
        height: 31px;
        padding: 7px 0 0 96px;
        letter-spacing: 13px;
    }
    .given_name{
        position: absolute;
        top: 394px;
        left: 5px;
        width: 768px;
        padding: 7px 0 0 90px;
        height: 31px;
        .given_name_div{
            display: inline-block;
            width: 20.3px;
            text-align: center;
        }
    }
    .user_sex{
        position: absolute;
        top: 464px;
        width: 183px;
        height: 45px;
        .boy{
            position:absolute;
            left: 60px;
            margin-top: 16px;
        }
        .girl{
            position: absolute;
            left: 127px;
            margin-top: 16px;
        }
    }
    .birthday{
        width: 281px;
        height: 43px;
        position: absolute;
        top: 465px;
        left: 181px;
        .birthday-year{
            position: absolute;
            top: 6px;
            left: 189px;
            letter-spacing: 13px;
        }
        .birthday-month{
            position: absolute;
            top: 6px;
            left: 133px;
            letter-spacing: 13px;
        }
        .birthday-day{
            position: absolute;
            top: 6px;
            left: 75px;
            letter-spacing: 13px;
        }
    }
    .user_married{
        position: absolute;
        top: 508px;
        width: 463px;
        height: 29px;
        .correct{
            position: absolute;
            margin-top: 11px;
        }
        .married2{
            left: 93px;
        }
        .married1{
            left: 169px;
        }
        .married4{
            left: 241px;
        }
        .married3{
            left: 324px;
        }
        .married5{
            left: 400px;
        }
    }
    .user_nationality{
        position: absolute;
        width: 325px;
        height: 30px;
        top: 508px;
        left: 462px;
        padding: 8px 0 0 41px;
    }
    .birth_place_aboard{
        position: absolute;
        top: 465px;
        left: 463px;
        width: 323px;
        height: 42px;
        padding: 12px 0 0 65px;
    }
    .address1{
        position: absolute;
        width: 336px;
        top: 877px;
        left:27px;
        line-height: 42px;
    }
    .address{
        position: absolute;
        width: 336px;
        top: 877px;
        left: 423px;
        line-height: 42px;
    }
    .address_country{
        position: absolute;
        top: 1003px;
        left: 5px;
        width: 291px;
        height: 37px;
        padding: 10px 0 0 135px;
    }
    .certificates_name{
        position: absolute;
        top: 555px;
        width: 130px;
        height: 42px;
        left: 5px;
        padding: 13px 0 0 47px
    }
    .certificates_hk_number{
        position: absolute;
        top: 555px;
        width: 352px;
        height: 42px;
        left: 135px;
        padding: 14px 0 0 48px;
        letter-spacing: 13.2px;
    }
    .certificates_hk_issue_at{
        position: absolute;
        top: 558px;
        left: 488px;
        width: 300px;
        height: 39px;
        padding: 11px 0 0 67px;
    }
    .certificates_hk_date{
        position: absolute;
        top: 598px;
        height: 54px;
        width: 294px;
        .certificates_hk_date-year{
            position: absolute;
            letter-spacing: 14px;
            left: 198px;
            margin-top: 19px;
        }
        .certificates_hk_date-month{
            position: absolute;
            letter-spacing: 14px;
            margin-top: 19px;
            left: 142px;
        }
        .certificates_hk_date-day{
            position: absolute;
            letter-spacing: 14px;
            margin-top: 19px;
            left: 83px;
        }
    }
    .certificates_hk_end_date{
        position: absolute;
        top: 598px;
        height: 54px;
        width: 294px;
        left: 287px;
        .certificates_hk_end_date-year{
            position: absolute;
            letter-spacing: 14px;
            left: 198px;
            margin-top: 19px;
        }
        .certificates_hk_end_date-month{
            position: absolute;
            letter-spacing: 14px;
            margin-top: 19px;
            left: 142px;
        }
        .certificates_hk_end_date-day{
            position: absolute;
            letter-spacing: 14px;
            margin-top: 19px;
            left: 83px;
        }
    }
    .certificates_number{
        position: absolute;
        top: 653px;
        left: 5px;
        height: 56px;
        width: 603px;
        padding: 19px 0 0 219px;
        letter-spacing: 13.2px;
    }
    .certificates_cn_issue_at{
        position: absolute;
        top: 710px;
        left: 5px;
        height: 56px;
        width: 241px;
        padding: 34px 0 0 11px;
    }
    .certificates_date{
        position: absolute;
        width: 362px;
        height: 57px;
        top: 709px;
        left: 246px;
        .certificates_date-year{
            position: absolute;
            top: 15px;
            left: 264px;
            letter-spacing: 14px;
        }
        .certificates_date-month{
            position: absolute;
            top: 15px;
            left: 207px;
            letter-spacing: 14px;
        }
        .certificates_date-day{
            position: absolute;
            top: 15px;
            left: 149px;
            letter-spacing: 14px;
        }
    }
    .user_mobile{
        position: absolute;
        width: 295px;
        height: 50px;
        top: 767px;
        box-sizing: border-box;
        padding-left: 96px;
        padding-top: 16px;
    }
    .info_em{
        position: absolute;
        width: 800px;
        height: 31px;
        top: 818px;
        box-sizing: border-box;
        padding-left: 93px;
        padding-top: 7px;
        .info_em_div{
            width: 20.2px;
            display: inline-block;
            text-align: center;
        }
    }
    .user_live{
        position: absolute;
        top: 1014px;
        left: 637px;
    }
}
.spouse_bg6{
    position: absolute;
    top: 925px;
    width: 600px;
    height: 77px;
    left: 8px;
    padding: 28px 0 0 37px;
}
.spouse_follow{
    .follow_correct{
        margin-left: 93px;
    }
    .spouse-follow{
        margin-left: 186px;
    }
}
.spouse_noFollow{
    padding: 28px 0 0 223px;
}


</style>
