<!--
 * @Author: filename
 * @Description: 学历信息
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12" id="pdf-college">
                <span>学校全称：</span>
                <span>
                    {{ data.college }}
                    <template v-if="data.is_top == 1">
                        (<i style="color: #8fc635;">百强名校</i>)
                    </template>
                    <template v-if="data.is_top == 2">
                        (<i style="color: red;">不被认可大学</i>)
                    </template>
                </span>
            </el-col>
            <el-col :span="12" id="pdf-type">
                <span>授课形式：</span>
                <span>{{ data.type }}</span>
            </el-col>
            <el-col :span="12" id="pdf-admission_time">
                <span>入学年月：</span>
                <span>{{ data.admission_time }}</span>
            </el-col>
            <el-col :span="12" id="pdf-graduate_time">
                <span>毕业年月：</span>
                <span>
                    {{ data.graduate_time }}
                    <template v-if="isReading">
                        <b style="color: red;">(在读)</b>
                    </template>
                </span>
            </el-col>
            <el-col :span="12" id="pdf-degree_type1">
                <span>专业：</span>
                <span>{{ data.professional }}</span>
            </el-col>
            <el-col :span="12" id="pdf-degree_type">
                <span>学位：</span>
                <span>
                    {{ data.background }}
                    <template v-if="data.degree_type">({{data.degree_type}})</template>
                </span>
            </el-col>
            <el-col :span="12" id="pdf-country">
                <span>上课地点：</span>
                <span v-if="data.school_address.country.includes('中国')">
                    {{ data.school_address.country + `-` + data.school_address.area[0] + `-` + data.school_address.area[1] }}
                </span>
                <span v-else>
                    {{ data.school_address.country + `-` + data.school_address.foreign }}
                </span>
            </el-col>
            <el-col :span="24" id="pdf-match_listing">
                <span>符合清单：</span>
                <span>
                    {{ data.match_listing == 0 ? '否' : data.match_listing == 1 ? '是' : ''}}
                </span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isReading: this.$dayjs(this.data.graduate_time) > this.$dayjs(new Date())
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
