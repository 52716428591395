<!--
 * @Author: Jacob.Zhang
 * @Description: 申请人信息
-->
<template>
    <div class="user-container common-part">
        <!-- 国籍 -->
        <!-- <el-form ref="nationForm" :model="currentItem" :rules="nationRules" label-width="140px" class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="港澳通行证：" prop="is_handled">
                        <el-select v-model="currentItem.is_handled" placeholder="请选择" clearable>
                            <el-option label="办理" :value="1" />
                            <el-option label="未办理" :value="0" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form> -->
        <!-- 主申请人信息 -->
        <el-form ref="currentItem" :model="currentItem" :rules="rules" label-width="140px" class="demo-ruleForm">
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="主申姓名：" prop="username">
                        <el-input v-model="currentItem.username" placeholder="姓名请与身份证一致" clearable auto-complete="off" maxlength="50" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="曾用名：" prop="used_name">
                        <el-input v-model="currentItem.used_name" placeholder="请与户口本保持一致" clearable auto-complete="off" maxlength="50" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚前姓氏：">
                        <el-input @input="surnameInput" v-model="currentItem.surname" placeholder="如有则填写，仅支持输入字母" clearable maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="姓名拼音：" prop="username_pinyin.family_name" class="pinyin">
                        <el-input v-model="currentItem.username_pinyin.family_name" clearable auto-complete="off" maxlength="50" placeholder="请输入姓氏拼音" />
                        <el-form-item prop="username_pinyin.given_name" class="add-fields">
                            <el-input v-model="currentItem.username_pinyin.given_name" clearable auto-complete="off" maxlength="50" placeholder="请输入名称拼音" />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="性别：" prop="sex">
                        <el-select v-model="currentItem.sex" placeholder="请选择" clearable>
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出生日期：" prop="birthday">
                        <el-date-picker v-model="currentItem.birthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出生城市：" :prop="`birth_place_aboard`" class="birth_city">
                        <el-radio-group v-model="currentItem.birth_place_aboard">
                            <el-radio :label="0">国内</el-radio>
                            <el-radio :label="1">国外</el-radio>
                        </el-radio-group>
                        <el-form-item v-if="currentItem.birth_place_aboard === 0" prop="birth_place.area" class="add-fields">
                            <CitySelect v-model="currentItem.birth_place.area" clearable />
                        </el-form-item>
                        <el-form-item v-if="currentItem.birth_place_aboard === 1" prop="birth_place.foreign" class="add-fields">
                            <el-input v-model="currentItem.birth_place.foreign" maxlength="100" placeholder="请输入" clearable auto-complete="off" />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号：" prop="mobile">
                        <el-input v-model.trim="currentItem.mobile" placeholder="请输入手机号" clearable auto-complete="off" maxlength="20" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱：" prop="email">
                        <el-input v-model.trim="currentItem.email" placeholder="请输入邮箱" clearable auto-complete="off" maxlength="25" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚姻状况：" prop="married">
                        <el-select v-model="currentItem.married" placeholder="请选择" clearable>
                            <el-option v-for="(item, index) in Object.values($CONFIG.MARITAL_STATUS_OPTIONS)" :key="index" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="国籍/地区：" prop="nationality">
                        <CountrySelect v-model="currentItem.nationality" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="现居住地址：" prop="address">
                        <CurrentAddressMacao ref="CurrentAddress" v-model="currentItem.address" :is_live_oversea_year.sync="currentItem.is_live_oversea_year"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否办理过澳门身份证：" prop="has_hk_id" label-width="180px">
                        <el-radio-group v-model="currentItem.has_hk_id">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="currentItem.has_hk_id === 1" label="澳门身份证号码：" prop="hk_id_number">
                        <el-input v-model.trim="currentItem.hk_id_number" clearable auto-complete="off" maxlength="25" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-divider />
        <!-- 中国身份证 -->
        <CnidForm ref="CnidForm"
            :card-info="currentItem.certificates.cn_identity_card"
            :required="currentItem.nationality.includes('中国-')"
        />
        <!-- 港澳通行证 -->
        <el-divider />
        <HkmPass ref="HkmPass" :card-info="currentItem.certificates.hk_macao_pass" />
        <!-- 护照 -->
        <el-divider />
        <Passport ref="Passport"
            :card-info="currentItem.certificates.passport"
            :required="!currentItem.nationality.includes('中国-') || !currentItem.address.country.includes('中国-')"
        />
    </div>
</template>

<script>
import CnidForm from '../../../../CnidForm/CnidForm.vue'
import HkmPass from '../../../../HkmPass/HkmPass.vue';
import Passport from '../../../../Passport/Passport.vue';
import { postClientInfo } from '../../../../../api/gifted'
export default {
    components: { CnidForm, HkmPass, Passport },
    props: {
        // 主申人信息
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        // this.currentItem = JSON.parse(JSON.stringify(this.info))
    },
    data() {
        return {
            nationRules: {
                is_handled: [{ required: true, message: "请选择签发地", trigger: "change", }],
            },
            rules: {
                username: [{ required: true, message: "请输入姓名", trigger: "blur", }],
                'username_pinyin.family_name': [{ required: true, message: "请输入姓氏拼音", trigger: "blur", }],
                'username_pinyin.given_name': [{ required: true, message: "请输入名称拼音", trigger: "blur", }],
                birthday: [{ required: true, message: "请选择出生日期", trigger: "change", }],
                birth_place_aboard: [{ required: true, message: "请选择国内/国外", trigger: "change", }],
                'birth_place.area': [{ required: true, message: "请选择出生城市", trigger: "change", }],
                'birth_place.foreign': [{ required: true, message: "请输入地址", trigger: "blur", }],
                sex: [{ required: true, message: "请选择性别", trigger: "change", }],
                married: [{ required: true, message: "请选择婚姻状况", trigger: "change", }],
                nationality: [{ required: true, message: "请输入国籍/地区", trigger: "change", }],
                mobile: [{ required: true, message: "请输入手机号", trigger: "blur", }],
                email: [{ required: true, trigger: "blur", validator: this.$rules.validate.email, }],
                address: [{ required: true, message: "请输入国家", trigger: "change", }],
                has_hk_id: [{ required: true, message: "请选择是否办理过澳门身份证", trigger: "change", }],
                hk_id_number: [{ required: true, trigger: "blur", validator: this.$rules.validate.macaoId }],
            },
            // currentItem: {}, // 主申人信息
        }
    },
    watch: {
        'currentItem.nationality'(newVal) {
            if(newVal && newVal.includes('中国-')) {
                this.$refs.Passport.clearCheck()
            }else {
                this.$refs.CnidForm.clearCheck()
            }
        },
        'currentItem.address.country'(newVal) {
            this.$refs.Passport.clearCheck()
            // 现居住国家变动 address_aboard
            if(newVal && newVal.includes('中国')) {
                this.currentItem.address_aboard = 0
            } else if(newVal && !newVal.includes('中国')) {
                this.currentItem.address_aboard = 1
            } else {
                this.currentItem.address_aboard = ""
            }
        },
    },
    methods: {
        // 婚前姓氏格式化
        surnameInput(value){
            this.currentItem.surname = value.replace(/[^a-zA-Z]/g, "").toLocaleUpperCase()
        },

        // 上传证件后的回调
        getCertificate(info) {
            // 图片
            this.currentItem.certificates[info.type][`img_${info.id_card_side}`] = info.url
            // 判断证件类型
            switch (info.type) {
                case 'cn_identity_card':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        if(!this.currentItem.nationality.includes('中国-')) return
                        // 外籍时 身份证信息不填入主申人信息
                        this.currentItem.username = info.username
                        this.currentItem.birthday = info.birthday
                        this.currentItem.sex = info.sex
                    }
                    if(info.id_card_side === 'back') {
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'hk_macao_pass':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'passport':
                    if(info.id_card_side === 'front') {
                        this.currentItem.certificates[info.type].number = info.number
                        this.currentItem.certificates[info.type].issue_at = info.issue_at
                        this.currentItem.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.currentItem.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                        if(this.currentItem.nationality.includes('中国-')) return
                        // 外籍时 护照信息填入主申人信息
                        this.currentItem.username = info.username
                        this.currentItem.birthday = info.birthday
                        this.currentItem.sex = info.sex
                    }
                    break;
                default:
                    break;
            }
            console.log(info)

        },

        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        async checkInfo() {
            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                // this.$refs.nationForm.validate(),
                this.$refs.currentItem.validate(),
                this.$refs.CurrentAddress.checkInfo(),
                // 证件信息
                this.$refs.CnidForm.checkInfo(),
                this.$refs.HkmPass.checkInfo(),
                this.$refs.Passport.checkInfo(),
            ])
            .then(async () => {
                this.submit()
            })
            .catch((err) => {
                console.log(err)
                this.$utils.scrollIntoError(this)
            })
        },

        async submit() {
            try {
                this.$emit('update:loading', true)
                const res = await postClientInfo({
                    ...this.currentItem,
                    next: 0, // 0保存 1下一步
                })
                if(res.code === 200) {
                    this.$message.success('保存成功')
                    await this.$utils.saveGiftedStatus(this.$route.params.id)
                    // this.$emit('update:currentItem', this.currentItem)
                    this.$emit('update')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$emit('update:loading', false)
            }
        },
    },
}
</script>

<style lang="less" scoped>

</style>
