<template>
    <!-- 父母信息 -->
    <div class="pdf-bg2">
        <!-- 父母信息 -->
        <div  v-if="family.parent.length > 0" v-for="(item, i) in family.parent" :key="i">
            <!-- 父母姓名 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-parent' + i }" :class="['family-name',`parent-top${i+1}`]">
                {{ item.subset_name }}
            </div>
            <!-- 父母性别 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-parent' + i }" :class="['family-sex',`parent-top${i+1}`]">
                {{ i === 0 ? '男':'女' }}
            </div>
            <div v-if="!item.deceased">
                <!-- 父母生日及地点 -->
                <div v-pdfbg:subset_address="{ value: '', id: 'pdf-parent' + i }">
                    <div v-pdfbg:subset_birthday="{ value: item.birthday, id: 'pdf-parent' + i }" :class="['family-birthday',`birthday${i+1}`,`parent-top${i+1}`]">
                        <div>
                            {{ item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' + item.birthday.split('-')[2] + '日'}}
                        </div>
                        <div>
                            <!-- item.birth_place.foreign -->
                            {{ item.birth_place_aboard === 0 ? item.birth_place.area.join('').replace('市辖区','') : item.birth_place.foreign }}
                        </div>
                    </div>
                </div>
                <!-- 父母婚姻状况 -->
                <div v-pdfbg:subset_married="{ value: item.married, id: 'pdf-parent' + i }" :class="['family-married',`parent-top${i+1}`]">
                    {{ Number(item.married) | _married }}
                </div>
                <!-- 父母职业名称 -->
                <div v-if="item.occupation == '在职'" v-pdfbg:subset_occupation_name="{ value: item.occupation_name, id: 'pdf-parent' + i }" :class="['family-occupation_name',`parent-top${i+1}`]">
                    {{ item.occupation_name }}
                </div>
                <div v-else v-pdfbg:subset_occupation="{ value: item.occupation_name, id: 'pdf-parent' + i }" :class="['family-occupation_name',`parent-top${i+1}`]">
                    {{ item.occupation }}
                </div>
                <!-- 父母现居住国家 -->
                <div v-pdfbg:subset_live_country="{ value: item.live_country?item.live_country.split('-')[0]:'', id: 'pdf-parent' + i }" :class="['family-live_country',`parent-top${i+1}`]">
                    {{ item.live_country?item.live_country.split('-')[0]:'' }}
                </div>
                <!-- 父母香港身份证号 -->
                <div v-if="item.child_has_hk_id.has === 'Y'" v-pdfbg:subset_hk_identity_card="{ value: item.hk_identity_card, id: 'pdf-parent' + i }" :class="['family-hk_identity_card',`parent-top${i+1}`]">
                    {{ item.hk_identity_card }}
                </div>
            </div>
        </div>
        <!-- 配偶信息 -->
        <div v-if="user.married === 1 || user.married === 4">
            <!-- 配偶姓名 -->
            <div v-pdfbg:spouse_name="{ value: family.spouse.subset_name, id: 'pdf-spouse'}" :class="['family-name','spouse-top']">
                {{ family.spouse.subset_name }}
            </div>
            <!-- 配偶性别 -->
            <div v-pdfbg:spouse_sex="{ value: family.spouse.sex, id: 'pdf-spouse'}" :class="['family-sex','spouse-top']">
                {{ family.spouse.sex | _sex }}
            </div>
            <!-- 配偶生日及地点 -->
            <div v-pdfbg:spouse_address="{ value: family.spouse.birthday, id: 'pdf-spouse'}">
                <div v-pdfbg:spouse_birthday="{ value: family.spouse.birthday, id: 'pdf-spouse'}" :class="['family-birthday','spouse-birthday','spouse-top']">
                    <div v-if="family.spouse.birthday">
                        {{ family.spouse.birthday.split('-')[0] + '年' + family.spouse.birthday.split('-')[1] + '月' + family.spouse.birthday.split('-')[2] + '日'}}
                    </div>
                    <div>
                        <span v-if="family.spouse.birth_place_aboard === 0">{{family.spouse.birth_place.area.join('').replace('市辖区','')}}</span>
                        <span v-else>{{family.spouse.birth_place.foreign}}</span>
                    </div>
                </div>
            </div>
            <!-- 配偶婚姻状况 -->
            <div v-pdfbg:spouse_name="{ value: '已婚', id: 'pdf-spouse'}" :class="['family-married','spouse-top']">
                已婚
            </div>
            <!-- 配偶职业名称 -->
            <div v-if="family.spouse.occupation == '在职'" v-pdfbg:spouse_occupation_name="{ value: family.spouse.occupation_name, id: 'pdf-spouse'}" :class="['family-occupation_name','spouse-top']">
                {{ family.spouse.occupation_name }}
            </div>
            <div v-else v-pdfbg:spouse_occupation="{ value: family.spouse.occupation_name, id: 'pdf-spouse'}" :class="['family-occupation_name','spouse-top']">
                {{ family.spouse.occupation }}
            </div>
            <!-- 配偶现居住国家 -->
            <!-- v-pdfbg:spouse_nationality="{ value: family.spouse.nationality, id: 'pdf-spouse'}" -->
            <!-- 判断是否随行 目前不随行时不定位 -->
            <div v-if="family.spouse.follow === 1">
                <!-- 判断是否同住 定位不同位置 -->
                <div v-if="family.spouse.nows === 1" v-pdfbg:spouse_isCohabitation="{ value: user.address.country.split('-')[0], id: 'pdf-spouse'}" :class="['family-live_country','spouse-top']">
                    <span>{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                </div>
                <div v-else v-pdfbg:spouse_nationality="{ value: family.spouse.address.country?user.address.country.split('-')[0]:'', id: 'pdf-spouse'}" :class="['family-live_country','spouse-top']">
                        {{ family.spouse.address.country?family.spouse.address.country.split('-')[0]:'' }}
                </div>
            </div>
            <div v-else>
                <div :class="['family-live_country','spouse-top']">
                    <span v-if="family.spouse.nows === 1">{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                    <span v-else>
                        {{ family.spouse.address.country?family.spouse.address.country.split('-')[0]:'' }}
                    </span>
                </div>
            </div>
            <!-- 配偶香港身份证号 -->
            <div v-if="family.spouse.child_has_hk_id.has === 'Y'" v-pdfbg:spouse_hk_identity_card="{ value: family.spouse.hk_identity_card, id: 'pdf-spouse'}" :class="['family-hk_identity_card','spouse-top']">
                {{ family.spouse.hk_identity_card }}
            </div>
            <!-- 是否跟随 -->
            <div v-if="family.spouse.follow === 1" v-pdfbg:spouse-follow="{ value: family.spouse.follow, id: 'pdf-spouse'}" :class="['family-follow','spouse-top']">
                <img class="correct" src="@/assets/img/correct.png" alt="">
            </div>
        </div>
        <!-- 子女信息 -->
        <div v-pdfbg:children-top-name="{ value: '', id: 'children-top'}" class="children-names"></div>
        <div class="children"  v-if="family.children.length > 0" v-for="(item, i) in family.children" :key="'child' + i">
            <!-- 子女姓名 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-children' + i }" :class="['family-name',`children-top${i+1}`]">
                {{ item.subset_name }}
            </div>
            <!-- 子女生日及地点 -->
            <div v-pdfbg:subset_address="{ value: item.birthday, id: 'pdf-children' + i }">
                <div v-pdfbg:subset_birthday="{ value: item.birthday, id: 'pdf-children' + i }" :class="['family-birthday',`birthday${i+1}`,`children-top${i+1}`]">
                    <div>
                        {{ item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' + item.birthday.split('-')[2] + '日'}}
                    </div>
                    <div>
                        <!-- item.birth_place.foreign -->
                        {{ item.birth_place_aboard === 0 ? item.birth_place.area.join('').replace('市辖区','') : item.birth_place.foreign }}
                    </div>
                </div>
            </div>
            <!-- 子女性别 -->
            <div v-pdfbg:subset_sex="{ value: item.sex, id: 'pdf-children' + i }" :class="['family-sex',`children-top${i+1}`]">
                {{ item.sex | _sex }}
            </div>
            <!-- 子女婚姻状况 -->
            <div v-pdfbg:subset_married="{ value: item.married, id: 'pdf-children' + i }" :class="['family-married',`children-top${i+1}`]">
                {{ Number(item.married) | _married }}
            </div>
            <!-- 子女职业名称 -->
            <div v-if="item.occupation == '在职'" v-pdfbg:subset_occupation_name="{ value: item.occupation_name, id: 'pdf-children' + i }" :class="['family-occupation_name',`children-top${i+1}`]">
                {{ item.occupation_name }}
            </div>
            <div v-else v-pdfbg:subset_occupation="{ value: item.occupation_name, id: 'pdf-children' + i }" :class="['family-occupation_name',`children-top${i+1}`]">
                {{ item.occupation }}
            </div>
            <!-- 子女现居住国家 -->
            <!-- v-pdfbg:subset_live_country="{ value: item.nationality, id: 'pdf-children' + i }" -->
            <!-- 判断是否随行 目前不随行时不定位 -->
            <div v-if="item.follow === 1">
                <!-- 判断是否同住 定位不同位置 -->
                <div v-if="item.nows === 1" v-pdfbg:children_isCohabitation="{ value: user.address.country?user.address.country.split('-')[0]:'', id: 'pdf-children' + i}" :class="['family-live_country',`children-top${i+1}`]">
                    <span>{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                </div>
                <div v-else v-pdfbg:subset_live_country="{ value: item.address.country?user.address.country.split('-')[0]:'', id: 'pdf-children' + i}" :class="['family-live_country',`children-top${i+1}`]">
                        {{ item.address.country?item.address.country.split('-')[0]:'' }}
                </div>
            </div>
            <div v-else>
                <div :class="['family-live_country',`children-top${i+1}`]">
                    <span v-if="item.nows === 1">{{user.address.country?user.address.country.split('-')[0]:''}}</span>
                    <span v-else>
                        {{ item.address.country?item.address.country.split('-')[0]:'' }}
                    </span>
                </div>
            </div>
            <!-- 配偶香港身份证号 -->
            <div v-if="item.child_has_hk_id.has === 'Y'" v-pdfbg:subset_hk_identity_card="{ value: item.hk_identity_card, id: 'pdf-children' + i}" :class="['family-hk_identity_card',`children-top${i+1}`]">
                {{ item.hk_identity_card }}
            </div>
            <!-- 是否跟随 -->
            <div v-if="item.follow === 1" v-pdfbg:subset_name="{ value: item.follow, id: 'pdf-children'+ i}" :class="['family-follow',`children-top${i+1}`]">
                <img class="correct" src="@/assets/img/correct.png" alt="">
            </div>
        </div>
        <!-- 兄弟信息 -->
        <div v-pdfbg:brothers-top-name="{ value: '', id: 'brothers-top'}" class="brothers-names"></div>
        <div class="brothers"  v-if="family.brothers.length > 0" v-for="(item, i) in family.brothers" :key="'brothers' + i">
            <!-- 兄弟姓名 -->
            <div v-pdfbg:subset_name="{ value: item.subset_name, id: 'pdf-brothers' + i }" :class="['family-name',`brothers-top${i+1}`]">
                {{ item.subset_name }}
            </div>
            <!-- 兄弟生日及地点 -->
            <div v-pdfbg:subset_address="{ value: item.birthday, id: 'pdf-brothers' + i }">
                <div v-pdfbg:subset_birthday="{ value: item.birthday, id: 'pdf-brothers' + i }" :class="['family-birthday',`birthday${i+1}`,`brothers-top${i+1}`]">
                    <div>
                        {{ item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' + item.birthday.split('-')[2] + '日'}}
                    </div>
                    <div>
                        <!-- item.birth_place.foreign -->
                        {{ item.birth_place_aboard === 0 ? item.birth_place.area.join('').replace('市辖区','') : item.birth_place.foreign }}
                    </div>
                </div>
            </div>
            <!-- 兄弟性别 -->
            <div v-pdfbg:subset_sex="{ value: item.birthday, id: 'pdf-brothers' + i }" :class="['family-sex',`brothers-top${i+1}`]">
                {{ item.sex | _sex }}
            </div>
            <!-- 兄弟婚姻状况 -->
            <div v-pdfbg:subset_married="{ value: item.married, id: 'pdf-brothers' + i }" :class="['family-married',`brothers-top${i+1}`]">
                {{ Number(item.married) | _married }}
            </div>
            <!-- 兄弟职业名称 -->
            <div v-pdfbg:subset_occupation="{ value: item.occupation, id: 'pdf-brothers' + i }" :class="['family-occupation_name',`brothers-top${i+1}`]">
                {{ item.occupation }}
            </div>
            <!-- 兄弟现居住国家 -->
            <div v-pdfbg:subset_live_country="{ value: item.live_country, id: 'pdf-brothers' + i }" :class="['family-live_country',`brothers-top${i+1}`]">
                {{ item.live_country?item.live_country.split('-')[0]:'' }}
            </div>
            <!-- 配偶香港身份证号 -->
            <div v-if="item.child_has_hk_id.has === 'Y'" v-pdfbg:subset_hk_identity_card="{ value: item.hk_identity_card, id: 'pdf-brothers' + i}" :class="['family-hk_identity_card',`brothers-top${i+1}`]">
                {{ item.hk_identity_card }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['family']),
        ...mapState('previewPdfGifted', ['user'])
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.correct{
    width: 10px;
}
.red {
    color: red;
}
.pdf-bg2 {
    height: 1200px;
    .parent-top1{
        position: absolute;
        top: 110px;
    }
    .parent-top2{
        position: absolute;
        top: 164px;
    }
    .children-names{
        position: absolute;
        height: 372px;
        width: 75px;
        top: 270px;
        left: 7px;
    }
    .brothers-names{
        position: absolute;
        height: 372px;
        width: 75px;
        top: 644px;
        left: 7px;
    }
    .family-name{
        left: 7px;
        width: 227px;
        height: 51px;
        padding: 1px 0 0 81px;
    }
    .family-sex{
        left: 235px;
        height: 51px;
        padding: 1px 2px;
        width: 30px;
    }
    .family-married{
        left: 359px;
        width: 92px;
        height: 51px;
        padding: 1px 2px;
    }
    
    .family-birthday{
        left: 265px;
        width: 95px;
        height: 51px;
    }
    .family-occupation_name{
        left: 452px;
        width: 123px;
        height: 51px;
        padding: 1px 2px;
    }
    .family-live_country{
        left: 576px;
        width: 82px;
        height: 51px;
        padding: 1px 2px;
    }
    .family-hk_identity_card{
        left: 660px;
        width: 82px;
        height: 51px;
        padding: 1px 2px;
    }
    .family-follow{
        left: 772px;
        padding-top: 12px;
    }
    // 配偶
    .spouse-top{
        position: absolute;
        top: 218px;
    }
    .children-top1{
        position: absolute;
        top: 271px;
    }
    .children-top2{
        position: absolute;
        top: 324px;
    }
    .children-top3{
        position: absolute;
        top: 378px;
    }
    .children-top4{
        position: absolute;
        top: 431px;
    }
    .children-top5{
        position: absolute;
        top: 485px;
    }
    .children-top6  {
        position: absolute;
        top: 538px;
    }
    .children-top6  {
        position: absolute;
        top: 591px;
    }
    .brothers-top1{
        position: absolute;
        top: 643px;
    }
    .brothers-top2{
        position: absolute;
        top: 689px;
    }
    .brothers-top3{
        position: absolute;
        top: 735px;
    }
    .brothers-top4{
        position: absolute;
        top: 782px;
    }
    .brothers-top5{
        position: absolute;
        top: 828px;
    }
    .brothers-top6{
        position: absolute;
        top: 874px;
    }
    .brothers-top7{
        position: absolute;
        top: 921px;
    }
    .brothers-top8{
        position: absolute;
        top: 968px;
    }
}

</style>