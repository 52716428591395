<template>
    <div class="pdf-other-box">
        <OtherSpouse v-if="activeName==='spouse'"></OtherSpouse>
        <OtherChildren v-else :dataNum="data" :key="data"></OtherChildren>
        <OtherUser />
        <div class="pdf-other-bg3"></div>
        <OtherFamily />
        <div ref="otherPdf"></div>
    </div>
</template>
<script>
// import { mapState } from 'vuex'
import OtherSpouse from './OtherSpouse.vue'
import OtherUser from './OtherUser.vue'
import OtherFamily from './OtherFamily.vue'
import OtherChildren from './OtherChildren.vue'
export default {
    components:{
        OtherSpouse,
        OtherChildren,
        OtherUser,
        OtherFamily
    },
    props: {
        data: {
            type: Number,
        },
        activeName:{
            type:String
        }
    },
    data() {
        return {
        }
    },
    computed: {
    },
    mounted(){
        window.addEventListener('scroll',this.handleScroll,true)
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        handleScroll() {
            let height = 4840-(this.$refs.otherPdf.getBoundingClientRect().top)
            let hh = parseInt(height/1190)
            this.$parent.pageScroll997(hh)
        },
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
.pdf-other-bg(@i) when (@i > 0) {
    .pdf-other-bg@{i} {
        position: relative;
        height: 1200px;
        background: url('https://upload.cdn.galaxy-immi.com/public/img/997/ID997B-@{i}.jpg') no-repeat;
        background-size: 100%;
        font-weight: @i;
    }
    .pdf-other-bg(( @i - 1 ));
}

.pdf-other-bg(4);

.pdf-other-box{
    overflow-y: auto;
    height: calc(100% - 42px);
}
</style>